import { toast } from 'react-toastify';

export const ToastType = {
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info',
};

export const setToastMessage = (message, type = ToastType.SUCCESS) => {
  const toastOptions = { autoClose: 2000, position: 'top-center' };
  toast.dismiss();

  if (type === ToastType.SUCCESS) {
    toast.success(message, toastOptions);
  } else if (type === ToastType.ERROR) {
    toast.error(message, toastOptions);
  } else if (type === ToastType.INFO) {
    toast.info(message, toastOptions);
  }
};

const Toast = { setToastMessage, ToastType };

export default Toast;
