const deleteImageCategoryLambdaRequest = async (
  imageCategoryIdToDelete,
  token,
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_DELETE_IMAGE_CATEGORY_LAMBDA_URL}?imageCategoryId=${imageCategoryIdToDelete}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );

    return {
      status: response.status,
      message: (await response.json()).message,
    };
  } catch (error) {
    return {
      status: 503,
      message: 'could not reach the server.',
    };
  }
};

export default deleteImageCategoryLambdaRequest;
