import { useContext, useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AgGridReact } from 'ag-grid-react';
// Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-grid.css';
//optional css for data grid
import 'ag-grid-community/styles/ag-theme-quartz.css';

import { useDebounce } from 'customHooks';
import ajaxRequest, { ajaxRequestMethods } from 'helpers/ajaxRequest';
import { AuthContext } from 'context/AuthProvider';
import Pagination from 'components/common/Pagination';
import SearchText from 'components/common/SearchText';
import ReactQueryParameters from 'constants/reactQueryParameters';

const EndDateRenderer = (props) => {
  return (
    <>
      {props.data.isActive
        ? '-'
        : new Date(props.value)
            .toLocaleString('en-CA', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
            })
            .replace(/\//g, '-')}
    </>
  );
};

const StartDateRenderer = (props) => {
  return (
    <>
      {new Date(props.value)
        .toLocaleString('en-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        })
        .replace(/\//g, '-')}
    </>
  );
};

const isActiveRenderer = (props) => {
  return <>{props.value ? 'Yes' : 'No'}</>;
};

const DiscountHistory = () => {
  const { auth } = useContext(AuthContext);
  const [searchTerm, setSearchTerm] = useState('');
  const q = useDebounce(searchTerm);
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    setPageNumber(0);
  }, [q]);

  const { data: discountHistoryData, isFetching: discountHistoryDataLoading } =
    useQuery({
      queryKey: ['discountHistory', { pageNumber, q }],
      queryFn: () =>
        ajaxRequest(
          `api/discount/history?pageNumber=${pageNumber}${q ? `&q=${q}` : ''}`,
          ajaxRequestMethods.GET,
          undefined,
          auth.accessToken,
        ),

      staleTime: ReactQueryParameters.queryStaleTime,
      gcTime: ReactQueryParameters.queryGcTime,
    });

  const colDefs = useRef([
    { field: '_id', headerName: 'Discount ID' },
    { field: 'percentage', width: 120 },
    { field: 'cta', headerName: 'CTA' },
    {
      field: 'isActive',
      cellRenderer: isActiveRenderer,
      width: 90,
    },
    {
      field: 'createdAt',
      headerName: 'Start Date',
      cellRenderer: StartDateRenderer,
    },
    {
      field: 'updatedAt',
      headerName: 'End Date',
      cellRenderer: EndDateRenderer,
    },
  ]);

  return (
    <div className="flex flex-col gap-y-4 pr-4">
      <SearchText
        containerClasses="w-80"
        onTextChange={setSearchTerm}
        placeholder="Search using dates (YYYY-MM-DD) or CTA..."
      />

      <div className="ag-theme-quartz" style={{ height: 500 }}>
        <AgGridReact
          rowData={discountHistoryData?.data.discounts}
          loading={discountHistoryDataLoading}
          columnDefs={colDefs.current}
          suppressMovableColumns={true}
        />
      </div>

      <Pagination
        currentPageNo={pageNumber}
        onPageChange={setPageNumber}
        pageCount={discountHistoryData?.data.totalPages}
      />
    </div>
  );
};

export default DiscountHistory;
