import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import routes from 'constants/routes';
import { AuthContext } from 'context/AuthProvider';

const RequireLogin = ({ children }) => {
  const { auth } = useContext(AuthContext);

  const navigate = useNavigate();
  const isLoggedIn = !!auth?.accessToken;

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(routes.login);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return <>{isLoggedIn && children}</>;
};

export default RequireLogin;
