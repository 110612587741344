import ReactPaginate from 'react-paginate';

import { ReactComponent as LeftCircle } from 'svgs/leftCircle.svg';
import { ReactComponent as RightCircle } from 'svgs/rightCircle.svg';
import './index.css';

const Pagination = ({ currentPageNo, onPageChange, pageCount }) => (
  <ReactPaginate
    containerClassName="pagination mx-auto"
    activeClassName="active"
    pageClassName="page-item"
    onPageChange={(event) => {
      onPageChange(event.selected);
    }}
    forcePage={currentPageNo}
    breakLabel="..."
    pageCount={pageCount}
    previousLabel={<LeftCircle style={{ color: '#B8C1CC' }} />}
    nextLabel={<RightCircle style={{ color: '#B8C1CC' }} />}
  />
);

export default Pagination;
