const generateSlug = (text) => {
  return text
    .toLowerCase()
    .trim()
    .replace(/[^\w]+/g, '-') // Replace non-alphanumeric characters with a hyphen
    .replace(/^-+|-+$/g, ''); // Remove leading or trailing hyphens
};

const isValidSlug = (slug) => {
  const slugRegex = /^[a-z0-9]+(-[a-z0-9]+)*$/;
  return slugRegex.test(slug);
};
const slugs = {
  latestDesigns: 'latest-designs',
  allDesigns: 'all-designs',
  packages: 'packages',
};

const slugUtils = { generateSlug, isValidSlug, slugs };
export default slugUtils;
