import { forwardRef } from 'react';

const FileUpload = forwardRef(
  (
    {
      label,
      onChange,
      error,
      clearError,
      multiple,
      acceptedFileTypes,
      containerClasses = '',
    },
    ref,
  ) => (
    <div className={`flex flex-col gap-y-1 ${containerClasses}`}>
      <div className="flex flex-col gap-y-1.5">
        {label && <label className="font-semibold">{label}</label>}
        <input
          type="file"
          accept={acceptedFileTypes}
          onChange={(e) => {
            onChange(multiple ? e.target.files : e.target.files[0]);
            if (e.target.files.length && clearError) clearError();
          }}
          multiple={multiple}
          ref={ref}
        />
      </div>
      {error && <span className="text-red-500">{error}</span>}
    </div>
  ),
);

export default FileUpload;
