import { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { useNavigate, useParams } from 'react-router-dom';

import ajaxRequest from 'helpers/ajaxRequest';
import { setToastMessage } from 'helpers/Toast';
import routerUtils from 'helpers/routerUtils';
import slugUtils from 'helpers/slugUtils';

const Download = () => {
  const navigate = useNavigate();
  const { downloadToken } = useParams();

  const [isDownloading, setIsDownloading] = useState(false);
  const [message, setMessage] = useState('Preparing Download!');

  useEffect(() => {
    if (downloadToken) setIsDownloading(true);
    ajaxRequest(`api/s3Url/download/paidResources/${downloadToken}`)
      .then((res) => {
        const url = URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.href = url;
        link.download = res.fileName || 'images.zip';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        setToastMessage('Download Success!');
        navigate(
          routerUtils.getMarketplaceNavigateUrl(
            slugUtils.slugs.latestDesigns,
            1,
          ),
        );
      })
      .catch((error) => {
        if (error.status === 401) setMessage('The download Link has expired');
        else setMessage('There was an error in downloading the data');
      })
      .finally(() => {
        setIsDownloading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadToken]);

  return (
    <div className="fixed inset-0 z-10 flex h-screen w-screen flex-col items-center justify-center gap-y-2 bg-gray-800/80">
      {isDownloading ? (
        <>
          <ReactLoading type="spin" color="blue" />
          <span className="text-3xl text-white">Downloading...</span>
          <span className="text-center text-xl text-white">
            In case the browser asks your permission to allow downloading the
            file, please allow.
          </span>
        </>
      ) : (
        <span className="text-3xl text-white"> {message} </span>
      )}
    </div>
  );
};

export default Download;
