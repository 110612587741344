import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { string, object } from 'yup';

import Button from 'components/common/Button';
import LabelAndInputText from './common/LabelAndInputText';
import { ReactComponent as CloseIcon } from 'svgs/close.svg';
import Regex from 'constants/regex';
import ajaxRequest, { ajaxRequestMethods } from 'helpers/ajaxRequest';
import Toast from 'helpers/Toast';

const emailSubscriptionSchema = object({
  email: string()
    .required()
    .matches(Regex.emailRegex, 'invalid email')
    .label('Email'),
});

const SubscriptionPopup = ({ onClose, onSuccess }) => {
  const [email, setEmail] = useState('');
  const [validationError, setValidationError] = useState('');

  const {
    mutate: requestSubscription,
    isPending: isRequestSubscriptionApiCallInProgress,
  } = useMutation({
    mutationFn: () =>
      ajaxRequest('api/subscription/request', ajaxRequestMethods.POST, {
        email,
      }),
    onSuccess: () => {
      onSuccess();
    },
    onError: (error) => {
      Toast.setToastMessage(error.message, Toast.ToastType.ERROR);
    },
  });
  const onSubscribeClick = () => {
    emailSubscriptionSchema
      .validate({ email })
      .then(() => {
        requestSubscription();
      })
      .catch((errors) => {
        setValidationError(errors.message); // Set error message from the Yup error
      });
  };
  return (
    <div className="fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-black/60">
      <div className="mx-2 w-96 max-w-full rounded-lg bg-white">
        <div className="flex items-center justify-between border-0 border-b border-solid border-gray-200 p-2">
          <label className="text-lg font-semibold">Email Subscription</label>
          <CloseIcon
            className="h-10 w-10 cursor-pointer text-blue-500"
            onClick={onClose}
          />
        </div>

        <div className="flex flex-col gap-y-2 p-2">
          <LabelAndInputText
            label="Email"
            onValueChange={(email) => setEmail(email)}
            value={email}
            error={validationError}
            clearError={() => setValidationError('')}
          />

          <div className="flex justify-center gap-2">
            <Button
              onClick={onSubscribeClick}
              className="w-24"
              disabled={isRequestSubscriptionApiCallInProgress}
            >
              Subscribe
            </Button>
            <Button
              className="w-24 bg-white"
              style={{
                border: 'rgb(59 130 246 ) 1px solid',
                color: 'rgb(59 130 246 )',
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPopup;
