import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext, useState } from 'react';

import AddNewDiscount from 'components/AdminComponents/Discount/AddNewDiscount';
import DiscountHistory from 'components/AdminComponents/Discount/DiscountHistory';
import Button from 'components/common/Button';
import ajaxRequest, { ajaxRequestMethods } from 'helpers/ajaxRequest';
import ReactQueryParameters from 'constants/reactQueryParameters';
import { confirmAlert } from 'react-confirm-alert';
import { AuthContext } from 'context/AuthProvider';
import Toast from 'helpers/Toast';

const Views = {
  DISCOUNT_HISTORY: 0,
  ADD_NEW_DISCOUNT: 1,
};

const Discount = () => {
  const [currentView, setCurrentView] = useState(Views.DISCOUNT_HISTORY);
  const {
    data: activeDiscountData,
    isFetching: isActiveDiscountDetailsLoading,
  } = useQuery({
    queryKey: ['activeDiscount'],
    queryFn: () => ajaxRequest(`api/discount`, ajaxRequestMethods.GET),
    staleTime: ReactQueryParameters.queryStaleTime,
    gcTime: ReactQueryParameters.queryGcTime,
  });

  const { auth } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const invalidateDiscountCache = () => {
    queryClient.invalidateQueries(['activeDiscount', 'discountHistory']);
  };

  const {
    mutate: deactivateCurrentDiscount,
    isPending: isDeactivateCurrentDiscountApiCallInProgress,
  } = useMutation({
    mutationFn: () =>
      ajaxRequest(
        'api/discount/deactivate',
        ajaxRequestMethods.PATCH,
        undefined,
        auth?.accessToken,
      ),
    onSuccess: () => {
      Toast.setToastMessage(
        'Discount deactivated Successfully.',
        Toast.ToastType.SUCCESS,
      );
      invalidateDiscountCache();
    },
    onError: (error) => {
      Toast.setToastMessage(error.message, Toast.ToastType.ERROR);
    },
  });

  const onDeactiveCurrentDiscountClick = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="fixed inset-0 z-50 flex h-screen w-screen items-center justify-center bg-black/50">
            <div className="w-fit rounded bg-white px-4 py-4">
              <h1>Are you sure?</h1>
              <p>
                You are going to deactivate the currently active discount. Are
                you sure?
              </p>
              <div className="flex gap-x-2">
                <Button className="px-4" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  className="bg-red-500"
                  onClick={() => {
                    deactivateCurrentDiscount();
                    invalidateDiscountCache();
                    onClose();
                  }}
                >
                  Proceed
                </Button>
              </div>
            </div>
          </div>
        );
      },
    });
  };
  return (
    <>
      {currentView === Views.DISCOUNT_HISTORY ? (
        <div className="flex w-full flex-col">
          <div className="flex gap-x-2 self-end pr-4">
            <Button
              disabled={
                !activeDiscountData?.data ||
                isActiveDiscountDetailsLoading ||
                isDeactivateCurrentDiscountApiCallInProgress
              }
              onClick={onDeactiveCurrentDiscountClick}
            >
              Deactivate Current Discount
            </Button>
            <Button onClick={() => setCurrentView(Views.ADD_NEW_DISCOUNT)}>
              Add New Discount
            </Button>
          </div>

          <DiscountHistory />
        </div>
      ) : (
        <AddNewDiscount
          onClose={() => setCurrentView(Views.DISCOUNT_HISTORY)}
        />
      )}
    </>
  );
};

export default Discount;
