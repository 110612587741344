import React, { createContext, useEffect, useReducer, useMemo } from 'react';

export const CartContext = createContext({ state: [], actions: {} });

const CartActionTypes = Object.freeze({
  ADD_TO_CART: 'ADD_TO_CART',
  REMOVE_FROM_CART: 'REMOVE_FROM_CART',
  LOAD_CART: 'LOAD_CART',
  CLEAR_CART: 'CLEAR_CART',
});

const cartActions = {
  //item is {imageId, imageName, imagePrice, imageUrl}
  addToCart: (item) => createAction(CartActionTypes.ADD_TO_CART, item),
  removeFromCart: (imageId) =>
    createAction(CartActionTypes.REMOVE_FROM_CART, { imageId }),
  loadCart: () => createAction(CartActionTypes.LOAD_CART),
  clearCart: () => createAction(CartActionTypes.CLEAR_CART),
};

const createAction = (type, payload) => ({ type, payload });

const loadCartFromLocalStorage = () => {
  try {
    const cart = localStorage.getItem('cart');
    return cart ? JSON.parse(cart) : [];
  } catch (error) {
    console.error('Failed to load cart from localStorage', error);
    return [];
  }
};

const saveCartToLocalStorage = (cart) => {
  try {
    localStorage.setItem('cart', JSON.stringify(cart));
  } catch (error) {
    console.error('Failed to save cart to localStorage', error);
  }
};

const cartReducer = (state, action) => {
  switch (action.type) {
    case CartActionTypes.LOAD_CART:
      return loadCartFromLocalStorage();

    case CartActionTypes.ADD_TO_CART:
      if (state.some((item) => item.imageId === action.payload.imageId)) {
        return state;
      }
      const updatedStateOnAdd = [
        ...state,
        {
          imageId: action.payload.imageId,
          imageName: action.payload.imageName,
          imagePrice: action.payload.imagePrice,
          imageUrl: action.payload.imageUrl,
        },
      ];
      saveCartToLocalStorage(updatedStateOnAdd);
      return updatedStateOnAdd;

    case CartActionTypes.REMOVE_FROM_CART:
      const updatedStateOnRemove = state.filter(
        (item) => item.imageId !== action.payload.imageId,
      );
      saveCartToLocalStorage(updatedStateOnRemove);
      return updatedStateOnRemove;

    case CartActionTypes.CLEAR_CART:
      saveCartToLocalStorage([]);
      return [];
    default:
      return state;
  }
};

const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, []);

  useEffect(() => {
    dispatch(cartActions.loadCart());
  }, []);

  const value = useMemo(
    () => ({
      cartItems: state,
      cartActions,
      dispatchCartAction: dispatch,
    }),
    [state],
  );

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export default CartProvider;
