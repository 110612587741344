import { useContext, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import LabelAndInputText from 'components/common/LabelAndInputText';
import Button from 'components/common/Button';
import ajaxRequest, { ajaxRequestMethods } from 'helpers/ajaxRequest';
import { setToastMessage, ToastType } from 'helpers/Toast';
import { AuthContext } from 'context/AuthProvider';
import { ReactComponent as EditIcon } from 'svgs/edit.svg';

export const MetaDataType = {
  IMAGE_PRICE: 'imagePrice',
  IMAGE_NAME: 'imageName',
};

const EditComp = ({ onClose, imageId, value, metadataFieldFor }) => {
  const { auth } = useContext(AuthContext);
  const [updatedValue, setUpdatedValue] = useState(value);
  const [validationError, setValidationError] = useState(false);
  const [updatePriceApiCallInProgress, setUpdatePriceApiCallInProgress] =
    useState(false);

  const queryClient = useQueryClient();

  const onSubmitUpdatedPrice = async () => {
    if (updatedValue === null || updatedValue === '') setValidationError(true);
    else if (value === updatedValue)
      setToastMessage(
        `You did not update image ${
          metadataFieldFor === MetaDataType.IMAGE_PRICE ? 'price' : 'name'
        }. Please update the value to proceed.`,
        ToastType.INFO,
      );
    else {
      try {
        setUpdatePriceApiCallInProgress(true);
        await ajaxRequest(
          `api/image/updateMetadata/${imageId}`,
          ajaxRequestMethods.PATCH,
          metadataFieldFor === MetaDataType.IMAGE_NAME
            ? { updatedImageName: updatedValue }
            : { updatedImagePrice: updatedValue },
          auth.accessToken,
        );

        setToastMessage(
          `Image ${
            metadataFieldFor === MetaDataType.IMAGE_NAME ? 'name' : 'price'
          } updated.`,
          ToastType.SUCCESS,
        );

        queryClient.invalidateQueries({ queryKey: ['image', imageId] });
        queryClient.invalidateQueries({ queryKey: ['image/latest'] });
        queryClient.invalidateQueries({ queryKey: ['image/all'] });
      } catch (error) {
        setToastMessage(error.message, ToastType.ERROR);
      } finally {
        setUpdatePriceApiCallInProgress(false);
        onClose();
      }
    }
  };

  return (
    <div className="flex items-center gap-x-2">
      <label className="font-semibold">
        Update {metadataFieldFor === MetaDataType.IMAGE_NAME ? 'Name' : 'Price'}
        :{' '}
      </label>
      <LabelAndInputText
        value={updatedValue}
        type={metadataFieldFor === MetaDataType.IMAGE_NAME ? 'text' : 'number'}
        onValueChange={(updatedValue) => setUpdatedValue(updatedValue)}
        min={0}
        error={validationError}
        clearError={() => setValidationError('')}
      />
      <Button
        className="py-1.5"
        onClick={onSubmitUpdatedPrice}
        disabled={updatePriceApiCallInProgress}
      >
        Submit
      </Button>
      <Button className="bg-gray-400 py-1.5 text-black" onClick={onClose}>
        Cancel
      </Button>
    </div>
  );
};

const MetaDataComp = ({ imageId, value, metadataFieldFor }) => {
  const [showEditComp, setShowEditComp] = useState(false);
  return (
    <div className="flex h-12 items-center gap-x-2 pt-4">
      {showEditComp ? (
        <EditComp
          onClose={() => {
            setShowEditComp(false);
          }}
          value={value}
          imageId={imageId}
          metadataFieldFor={metadataFieldFor}
        />
      ) : (
        <>
          <label className="font-semibold">
            Image{' '}
            {metadataFieldFor === MetaDataType.IMAGE_NAME ? 'Name' : 'Price'}:
          </label>
          <span>{value}</span>
          <EditIcon
            className="h-3.5 w-3.5 rounded p-0.5 hover:cursor-pointer hover:bg-slate-300"
            onClick={() => setShowEditComp(true)}
          />
        </>
      )}
    </div>
  );
};

export default MetaDataComp;
