import { useContext } from 'react';

import { AuthContext } from 'context/AuthProvider';

export const withIsAdminLoggedInProp = (WrappedComp) => {
  return (props) => {
    const { auth } = useContext(AuthContext);
    const isAdminLoggedIn = auth.accessToken;

    return <WrappedComp {...props} isAdminLoggedIn={isAdminLoggedIn} />;
  };
};
