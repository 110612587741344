import { ReactComponent as Search } from 'svgs/search.svg';

const SearchText = ({
  onTextChange,
  currentSearchText,
  containerClasses,
  containerStyles,
  placeholder,
}) => {
  return (
    <div
      style={{
        ...containerStyles,
      }}
      className={`flex h-5 items-center justify-between rounded-lg border border-solid border-blue-500 p-2 ${containerClasses}`}
    >
      <input
        id="searchInput"
        type="text"
        onChange={(e) => onTextChange(e.target.value)}
        style={{ height: '90%' }}
        className="m-1 w-full border-none outline-none"
        placeholder={placeholder ? placeholder : 'Search...'}
        value={currentSearchText}
      />
      <div className="m-2.5">
        <Search />
      </div>
    </div>
  );
};

export default SearchText;
