import { ReactComponent as PaypalIcon } from 'svgs/paypal.svg';
const PaypalButton = ({ onClick, disabled, className = '' }) => {
  return (
    <button
      style={{ background: disabled ? 'gray' : '#FFC43A' }}
      className={`flex cursor-pointer items-center justify-center gap-x-2 rounded-lg border-none px-4 py-2 disabled:cursor-not-allowed ${className} enabled:active:scale-95`}
      disabled={disabled}
      onClick={onClick}
    >
      <label style={{ color: '#253B80', cursor: 'inherit' }}>Pay with</label>
      <PaypalIcon />
    </button>
  );
};
export default PaypalButton;
