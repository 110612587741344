const routes = {
  marketplace: '/:currentImageCategorySlug/page/:pageNumber',
  download: '/download/:downloadToken',
  executePaypalPayment: '/paypal/execute',
  verifyEmail: '/verify-email/:token',
  unsubcribe: '/unsubscribe',

  adminAppHome: '/adminapp',
  login: '/adminapp/login',
  addNewImage: '/adminapp/images/add',
  images: '/adminapp/images',
  imageCategories: '/adminapp/imageCategories',
  addNewImageCategory: '/adminapp/imageCategories/add',
  orderHistory: '/adminapp/orderHistory',
  discount: '/adminapp/discount',
};

export default routes;
