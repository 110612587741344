import { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';

import { ReactComponent as ZoomInIcon } from 'components/Marketplace/svgs/zoomin.svg';
import { ReactComponent as ZoomOutIcon } from 'components/Marketplace/svgs/zoomout.svg';
import ga from 'helpers/ga';

const buttonClasses =
  'bg-blue-500 text-white border-none rounded-lg text-lg font-medium cursor-pointer flex justify-center items-center py-1.5 hover:bg-blue-600 hover:shadow-lg active:scale-90';

const SCALE_FACTOR = 0.5;
const MAX_SCALE = 10;
const MIN_SCALE = 1;

const CanvasOptions = ({ onZoomIn, onZoomOut, onClose }) => {
  return (
    <div className="z-10 flex gap-x-4">
      <button className={buttonClasses}>
        <ZoomInIcon className="h-8 w-8" onClick={onZoomIn} />
      </button>
      <button className={buttonClasses}>
        <ZoomOutIcon className="h-8 w-8" onClick={onZoomOut} />
      </button>

      <button className={buttonClasses} onClick={onClose}>
        Close
      </button>
    </div>
  );
};

const ImageCanvas = ({ imgSrc, imageName, onClose, disableGA = false }) => {
  const imgRef = useRef();
  const [scale, setScale] = useState(1);

  const onZoomIn = () => {
    if (!disableGA && scale < MAX_SCALE) {
      ga.sendGaEvent(
        ga.GaEventCategories.IMAGE_INTERACTION.category,
        ga.GaEventCategories.IMAGE_INTERACTION.events.PREVIEW_IMAGE_ZOOM_IN,
        imageName,
      );
    }

    setScale((prev) => (prev === MAX_SCALE ? prev : prev + SCALE_FACTOR));
  };

  const onZoomOut = () => {
    if (!disableGA && scale > MIN_SCALE) {
      ga.sendGaEvent(
        ga.GaEventCategories.IMAGE_INTERACTION.category,
        ga.GaEventCategories.IMAGE_INTERACTION.events.PREVIEW_IMAGE_ZOOM_OUT,
        imageName,
      );
    }

    setScale((prev) => (prev <= MIN_SCALE ? prev : prev - SCALE_FACTOR));
  };

  const onEscapePress = (event) => {
    if (event.key === 'Escape') onClose();
  };

  useEffect(() => {
    document.addEventListener('keydown', onEscapePress);
    return () => document.removeEventListener('keydown', onEscapePress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      onClick={onClose}
      className="fixed inset-0 z-10 flex h-screen w-screen flex-col items-center justify-center gap-y-2 bg-black/40"
    >
      <Draggable>
        <div onClick={(e) => e.stopPropagation()} style={{ maxHeight: '70%' }}>
          <img
            draggable={false}
            src={imgSrc}
            ref={imgRef}
            alt="preview"
            style={{ transform: `scale(${scale})` }}
            className="transition-scale h-full w-full duration-200"
          />
        </div>
      </Draggable>

      <div onClick={(e) => e.stopPropagation()} className="fixed bottom-10">
        <CanvasOptions
          onZoomIn={onZoomIn}
          onZoomOut={onZoomOut}
          onClose={onClose}
        />
      </div>
    </div>
  );
};

export default ImageCanvas;
