import React, {
  createContext,
  useEffect,
  useReducer,
  useMemo,
  useState,
} from 'react';

const authInitialState = {
  accessToken: '',
  expiresAt: '',
};
export const AuthContext = createContext({
  state: authInitialState,
  actions: {},
});

const AuthActionTypes = Object.freeze({
  RESET: 'RESET',
  SET_AUTH: 'SET_AUTH',
  LOAD_AUTH_FROM_LOCAL_STORAGE: 'LOAD_AUTH_FROM_LOCAL_STORAGE',
});

const authActions = {
  //auth is {accessToken, expiresAt}
  setAuth: (auth) => createAction(AuthActionTypes.SET_AUTH, auth),
  reset: () => createAction(AuthActionTypes.RESET),
  loadAuthFromLocalStorage: () =>
    createAction(AuthActionTypes.LOAD_AUTH_FROM_LOCAL_STORAGE),
};

const createAction = (type, payload) => ({ type, payload });

const loadAuthFromLocalStorage = () => {
  try {
    const auth = localStorage.getItem('auth');
    return auth ? JSON.parse(auth) : authInitialState;
  } catch (error) {
    console.error('Failed to load auth from localStorage', error);
    return authInitialState;
  }
};

const saveAuthToLocalStorage = (auth) => {
  try {
    localStorage.setItem('auth', JSON.stringify(auth));
  } catch (error) {
    console.error('Failed to save auth to localStorage', error);
  }
};

const AuthReducer = (state, action) => {
  switch (action.type) {
    case AuthActionTypes.LOAD_AUTH_FROM_LOCAL_STORAGE:
      return loadAuthFromLocalStorage();

    case AuthActionTypes.SET_AUTH:
      saveAuthToLocalStorage(action.payload);
      return {
        accessToken: action.payload.accessToken,
        expiresAt: action.payload.expiresAt,
      };

    case AuthActionTypes.RESET:
      localStorage.removeItem('auth');
      return authInitialState;

    default:
      return state;
  }
};

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, []);
  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    dispatch(authActions.loadAuthFromLocalStorage());
    setAuthLoading(false);
  }, []);

  const value = useMemo(
    () => ({
      auth: state,
      authActions,
      dispatchAuthAction: dispatch,
    }),
    [state],
  );

  return (
    <AuthContext.Provider value={value}>
      {!authLoading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
