import Select from 'react-select';

const LabelAndDropdown = ({
  label,
  options,
  currentSelection,
  onSelectionChange,
  error,
  clearError,
  multipleSelection = false,
  disabled = false,
  dropdownClasses = '',
  containerClasses = '',
}) => {
  const selectedOptions = multipleSelection
    ? options.filter((option) => currentSelection.includes(option.value))
    : options.find((option) => option.value === currentSelection) || '';

  const handleChange = (selectedOptions) => {
    if (multipleSelection) {
      const selectedValues = selectedOptions
        ? selectedOptions.map((option) => option.value)
        : [];
      onSelectionChange(selectedValues);
    } else {
      const selectedValue = selectedOptions ? selectedOptions.value : '';
      onSelectionChange(selectedValue);
    }
    clearError();
  };

  return (
    <div className="flex flex-col gap-y-1">
      <div className={`flex flex-col gap-y-1.5 ${containerClasses}`}>
        <label className="font-semibold">{label}</label>
        <Select
          isMulti={multipleSelection}
          value={selectedOptions}
          options={options}
          onChange={handleChange}
          className={dropdownClasses}
          isDisabled={disabled}
          styles={{
            control: (provided, _) => ({
              ...provided,
              ...(error && { borderColor: 'red' }),
            }),
          }}
        />
      </div>
      {error && <span className="text-red-500">{error}</span>}
    </div>
  );
};

export default LabelAndDropdown;
