const Button = ({ disabled, onClick, className, children, style }) => (
  <button
    disabled={disabled}
    style={style}
    className={`rounded border-none p-2 ${
      disabled
        ? 'cursor-not-allowed bg-gray-500 text-gray-100'
        : 'cursor-pointer bg-blue-500 text-white'
    } ${className || ''}`}
    onClick={onClick}
  >
    {children}
  </button>
);

export default Button;
