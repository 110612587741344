const DiscountConfetti = ({ className }) => (
  <div
    className={`h-full w-full bg-cover bg-no-repeat ${className}`}
    style={{
      backgroundImage:
        "url('/discount-confetti-small.gif'), url('/discount-confetti-big.gif')",
      backgroundPosition: '50%',
    }}
  ></div>
);

const Discount = ({ activeDiscountData }) => {
  return (
    <div
      className={`flex w-full items-center justify-center bg-red-500 bg-gradient-to-br from-red-400 from-5% to-pink-600 to-100% text-white transition-all duration-1000 sm:justify-between ${
        activeDiscountData ? 'h-12' : 'h-0'
      }`}
    >
      <DiscountConfetti className="hidden sm:block" />
      <DiscountConfetti className="hidden lg:block" />
      <div className="py-2 text-center text-sm font-bold sm:min-w-[400px] sm:text-base">
        {activeDiscountData?.cta}
      </div>
      <DiscountConfetti className="hidden sm:block" />
      <DiscountConfetti className="hidden lg:block" />
    </div>
  );
};

export default Discount;
