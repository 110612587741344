const LabelAndInputText = ({
  value,
  onValueChange,
  error,
  clearError,
  label,
  placeholder,
  type,
  min,
  max,
  containerClasses = '',
  hideErrorText = false,
  disabled,
}) => (
  <div className={`flex flex-col gap-y-1 ${containerClasses}`}>
    <div className="flex flex-col gap-y-1.5">
      <label className="font-semibold">{label}</label>
      <input
        className={`h-6 rounded border border-solid px-1.5 disabled:cursor-not-allowed disabled:bg-gray-200 ${
          error ? 'border-red-500' : 'border-gray-300'
        }`}
        type={type || 'text'}
        value={value}
        onChange={(e) => {
          const value =
            type === 'number'
              ? e.target.value === ''
                ? null
                : Number(e.target.value)
              : e.target.value;
          onValueChange(value);
          clearError();
        }}
        placeholder={placeholder}
        min={min}
        max={max}
        disabled={disabled}
      />
    </div>
    {onValueChange && !hideErrorText && (
      <span className="text-red-500">{error}</span>
    )}
  </div>
);

export default LabelAndInputText;
