import { useContext, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import LabelAndDropdown from 'components/common/LabelAndDropdown';
import { ReactComponent as EditIcon } from 'svgs/edit.svg';
import Button from 'components/common/Button';
import ArrayUtils from 'helpers/arrayUtils';
import { setToastMessage, ToastType } from 'helpers/Toast';
import ajaxRequest, { ajaxRequestMethods } from 'helpers/ajaxRequest';
import { AuthContext } from 'context/AuthProvider';

const ImageCategoryDetails = ({
  allImageCategories,
  currentImageCategoryIds,
  imageId,
}) => {
  const [updatedImageCategoryIds, setUpdatedImageCategoryIds] = useState(
    currentImageCategoryIds,
  );
  const [showEditScreen, setShowEditScreen] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [
    updateImageCategoryIdsApiCallInProgress,
    setUpdateImageCategoryIdsApiCallInProgress,
  ] = useState(false);
  const { auth } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const onSubmitUpdatedImageCategoryIds = async () => {
    if (
      ArrayUtils.arraysHaveSameElements(
        currentImageCategoryIds,
        updatedImageCategoryIds,
      )
    ) {
      setToastMessage(
        'You did not update image categories. Please update to proceed.',
        ToastType.INFO,
      );
    } else if (updatedImageCategoryIds.length === 0) {
      setValidationError(true);
    } else {
      try {
        setUpdateImageCategoryIdsApiCallInProgress(true);
        await ajaxRequest(
          `api/image/updateMetadata/${imageId}`,
          ajaxRequestMethods.PATCH,
          { updatedImageCategoryIds },
          auth.accessToken,
        );
        queryClient.invalidateQueries({ queryKey: ['image', imageId] });
        queryClient.invalidateQueries({ queryKey: ['image/latest'] });
        queryClient.invalidateQueries({ queryKey: ['image/all'] });
        setToastMessage('Image Categories updated successfully.');
        setShowEditScreen(false);
      } catch (error) {
        setToastMessage(error.message, ToastType.ERROR);
      } finally {
        setUpdateImageCategoryIdsApiCallInProgress(false);
      }
    }
  };
  return (
    <div className="flex items-center gap-x-2">
      <LabelAndDropdown
        label="Image Categories:"
        options={allImageCategories}
        currentSelection={updatedImageCategoryIds}
        multipleSelection
        dropdownClasses="w-fit"
        containerClasses="!flex-row gap-x-2 items-center"
        disabled={!showEditScreen}
        onSelectionChange={(updatedImageCategoryIds) =>
          setUpdatedImageCategoryIds(updatedImageCategoryIds)
        }
        clearError={() => setValidationError(false)}
        error={validationError}
      />
      {showEditScreen ? (
        <>
          <Button
            className="py-1.5"
            onClick={onSubmitUpdatedImageCategoryIds}
            disabled={updateImageCategoryIdsApiCallInProgress}
          >
            Submit
          </Button>
          <Button
            className="bg-gray-400 py-1.5 text-black"
            onClick={() => {
              setValidationError(false);
              setShowEditScreen(false);
              setUpdatedImageCategoryIds(currentImageCategoryIds);
            }}
          >
            Cancel
          </Button>
        </>
      ) : (
        <EditIcon
          className="h-3.5 w-3.5 rounded p-0.5 hover:cursor-pointer hover:bg-slate-300"
          onClick={() => setShowEditScreen(true)}
        />
      )}
    </div>
  );
};

export default ImageCategoryDetails;
