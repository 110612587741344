import { useContext, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLocation, useParams } from 'react-router-dom';

import { CartContext } from 'context/CartProvider';
import ImageCanvas from 'components/ImageCanvas';
import Button from 'components/common/Button';
import { ReactComponent as ThumbsUpIcon } from 'svgs/thumbs-up.svg';
import slugUtils from 'helpers/slugUtils';
import ga from 'helpers/ga';

const selectedImageInitialState = {
  imageUrl: '',
  imageName: '',
};

export const ImagesGrid = ({ imagesData, imagesDataLoading }) => {
  const { cartItems, cartActions, dispatchCartAction } =
    useContext(CartContext);

  const location = useLocation();
  const { pageNumber } = useParams();

  const [selectedImage, setSelectedImage] = useState(selectedImageInitialState);

  const onPreviewImageClick = (imageUrl, imageName) => {
    setSelectedImage({ imageUrl, imageName });
    ga.sendGaEvent(
      ga.GaEventCategories.IMAGE_INTERACTION.category,
      ga.GaEventCategories.IMAGE_INTERACTION.events.PREVIEW_IMAGE_CLICKED,
      imageName,
    );
  };
  return (
    <div className="mb-4 flex w-full flex-wrap items-center justify-center gap-4 lg:gap-6">
      {imagesDataLoading
        ? Array.from({ length: 20 }, (_, index) => (
            <div
              key={`item-${index}-loading`}
              className="h-[150px] w-36 animate-pulse rounded-lg bg-gray-500 lg:h-[180px] lg:w-48"
            ></div>
          ))
        : imagesData?.map((image, index) => (
            <div
              key={image.imageId}
              className="relative flex h-[150px] w-36 flex-col items-center rounded-lg border border-solid border-gray-400 px-1 py-2 shadow-md lg:h-[180px] lg:w-48 lg:shadow-lg"
            >
              {location.pathname.includes(slugUtils.slugs.latestDesigns) &&
                index < 10 &&
                pageNumber === '1' && (
                  <div
                    className="absolute right-2 top-3 flex items-center justify-center rounded-xl bg-red-600 px-2 py-1 text-white"
                    style={{ fontSize: '10px' }}
                  >
                    New
                  </div>
                )}
              <img
                src={image.imageUrl}
                alt={image.imageName}
                className="h-[100px] max-w-full cursor-zoom-in lg:h-[130px]"
                onClick={() =>
                  onPreviewImageClick(image.imageUrl, image.imageName)
                }
              />

              <div className="mt-1 flex w-full flex-col gap-y-2">
                <div className="flex flex-row justify-between text-xs lg:text-sm">
                  <span className="font-semibold">{image.imageName}</span>

                  <span>${image.imagePrice}</span>
                </div>
                {cartItems.some(
                  (cartItem) => cartItem.imageId === image.imageId,
                ) ? (
                  <ThumbsUpIcon className="absolute -bottom-0.5 right-1 w-8" />
                ) : (
                  <Button
                    className="absolute bottom-1 right-1 rounded-lg !p-1 text-sm"
                    onClick={() => {
                      dispatchCartAction(cartActions.addToCart(image));
                    }}
                  >
                    Buy+
                  </Button>
                )}
              </div>
            </div>
          ))}

      {selectedImage.imageUrl &&
        createPortal(
          <ImageCanvas
            imgSrc={selectedImage.imageUrl}
            imageName={selectedImage.imageName}
            onClose={() => setSelectedImage(selectedImageInitialState)}
          />,
          document.body,
        )}
    </div>
  );
};

export default ImagesGrid;
