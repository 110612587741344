import routes from 'constants/routes';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AuthContext } from 'context/AuthProvider';

const SidebarItem = ({ label, onClick, isActive }) => (
  <div
    onClick={onClick}
    className={`cursor-pointer border-0 border-t border-solid border-gray-500 p-3 hover:bg-blue-200 ${
      isActive ? 'bg-gray-500' : ''
    }`}
  >
    {label}
  </div>
);
const Sidebar = () => {
  const navigate = useNavigate();
  const { authActions, dispatchAuthAction } = useContext(AuthContext);
  const location = useLocation();

  return (
    <div className="flex h-full w-[250px] flex-col bg-gray-100">
      <SidebarItem
        isActive={location.pathname === routes.addNewImage}
        label="Add New Image"
        onClick={() => navigate(routes.addNewImage)}
      />
      <SidebarItem
        label="Images"
        isActive={location.pathname === routes.images}
        onClick={() => navigate(routes.images)}
      />
      <SidebarItem
        label="Image Categories"
        isActive={location.pathname === routes.imageCategories}
        onClick={() => navigate(routes.imageCategories)}
      />
      <SidebarItem
        isActive={location.pathname === routes.addNewImageCategory}
        label="Add New Image Category"
        onClick={() => navigate(routes.addNewImageCategory)}
      />

      <SidebarItem
        isActive={location.pathname === routes.OrderHistory}
        label="Order History"
        onClick={() => navigate(routes.orderHistory)}
      />
      <SidebarItem
        isActive={location.pathname === routes.discount}
        label="Discount"
        onClick={() => navigate(routes.discount)}
      />
      <SidebarItem
        label="Logout"
        onClick={() => {
          dispatchAuthAction(authActions.reset());
        }}
      />
    </div>
  );
};

export default Sidebar;
