import { useNavigate } from 'react-router-dom';

import Sidebar from 'components/AdminComponents/AdminMasterPage/Sidebar';
import Button from 'components/common/Button';
import routerUtils from 'helpers/routerUtils';
import slugUtils from 'helpers/slugUtils';

const AdminMasterPage = ({ children }) => {
  const navigate = useNavigate();
  return (
    <div className="w-full">
      <div className="flex items-center gap-x-2">
        <h1 className="pl-2">Admin App</h1>
        <Button
          className="mt-2"
          onClick={() => {
            navigate(
              routerUtils.getMarketplaceNavigateUrl(
                slugUtils.slugs.latestDesigns,
                1,
              ),
            );
          }}
        >
          Switch to User App
        </Button>
      </div>
      <div className="mt-4 flex flex-row gap-x-10">
        <Sidebar />
        {children}
      </div>
    </div>
  );
};

export default AdminMasterPage;
