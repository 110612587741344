import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/common/Button';
import { withIsAdminLoggedInProp } from 'components/HOCs';
import routes from 'constants/routes';
import { useDebounce } from 'customHooks';
import { ReactComponent as ArrowUpIcon } from 'svgs/arrow-up.svg';
import SubscriptionPopup from 'components/SubscriptionPopup';
import SubscriptionVerificationLinkSentPopup from 'components/SubscriptionVerificationLinkSentPopup';

const PopupViews = {
  NONE: -1,
  SUBSCRIBE: 0,
  VERIFICATION_EMAIL_SENT: 1,
};
const Header = ({ isAdminLoggedIn }) => {
  const navigate = useNavigate();
  const [showHeader, setShowHeader] = useState(true);

  const showHeaderDebounced = useDebounce(showHeader, 100);
  const [currentPopup, setCurrentPopup] = useState(PopupViews.NONE);

  return (
    <>
      <div
        className={`relative box-border w-full bg-gray-300 sm:box-content sm:w-auto sm:max-w-full ${
          showHeader
            ? 'h-[170px] w-fit p-4 sm:h-[120px] lg:h-[165px]'
            : 'h-3 px-4'
        } flex flex-row justify-between gap-x-2 rounded-b rounded-t-none font-impact transition-all duration-200`}
      >
        {showHeaderDebounced && (
          <>
            <div className="flex items-center justify-center gap-x-4">
              <img
                src="/logo.svg"
                className="h-[80px] w-[80px] sm:h-[100px] sm:w-[120px] lg:h-[150px] lg:w-[150px]"
                alt="epic dxf designs logo"
              />
              {isAdminLoggedIn && (
                <Button
                  onClick={() => navigate(routes.adminAppHome)}
                  className="h-10 bg-gray-50 !text-black"
                >
                  Switch to Adminapp
                </Button>
              )}
            </div>
            <div className="flex flex-col items-center gap-y-0.5 text-center text-sm lg:gap-y-1 lg:text-[16px]">
              <span className="text-lg sm:text-2xl lg:text-6xl">
                EPIC DXF DESIGNS
              </span>
              <span>CUT READY FILES FOR LASER/PLASMA CUTTING</span>
              <span>DXF/SVG/EPS/PNG</span>
              <span>
                Contact us:{' '}
                <a
                  className="underline-offset-2"
                  href="mailto:epicdxfdesigns@gmail.com"
                >
                  epicdxfdesigns@gmail.com
                </a>
              </span>
              <div className="flex items-center justify-center gap-x-2">
                <span style={{ fontSize: '16px' }}>
                  Want to receive updates?{' '}
                </span>
                <Button
                  className="rounded-xl bg-red-500"
                  style={{ fontSize: '11px' }}
                  onClick={() => setCurrentPopup(PopupViews.SUBSCRIBE)}
                >
                  Subscribe
                </Button>
              </div>
            </div>
            <div className="hidden flex-col items-center justify-center gap-y-2 sm:flex">
              <label className="text-xl lg:text-3xl">
                ABOUT EPIC DXF DESIGNS
              </label>

              <div className="w-[230px] text-justify text-[11px] lg:w-[300px] lg:text-sm">
                Hello its Arslan here! I have been doing CAD designs since 2016.
                I am a professional dxf designer. I have clients from all over
                the world. I do dxf designs for both laser and plasma cutting. I
                do custom designs too and deliver ready to cut files. You can
                contact me anytime for quotes and queries.
              </div>
            </div>
          </>
        )}
        <button
          onClick={() => setShowHeader(!showHeader)}
          className={`z-10 ${
            showHeader
              ? 'absolute bottom-0 right-0'
              : 'absolute right-0 top-1 z-50'
          }`}
        >
          <ArrowUpIcon
            className={`h-3 w-3 ${showHeader ? '' : 'rotate-180'}`}
          />
        </button>
      </div>

      {currentPopup === PopupViews.SUBSCRIBE ? (
        <SubscriptionPopup
          onClose={() => setCurrentPopup(PopupViews.NONE)}
          onSuccess={() => setCurrentPopup(PopupViews.VERIFICATION_EMAIL_SENT)}
        />
      ) : currentPopup === PopupViews.VERIFICATION_EMAIL_SENT ? (
        <SubscriptionVerificationLinkSentPopup
          onClose={() => setCurrentPopup(PopupViews.NONE)}
        />
      ) : null}
    </>
  );
};

export default withIsAdminLoggedInProp(Header);
