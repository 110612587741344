import { useContext, useEffect, useRef, useState } from 'react';

import { CartContext } from 'context/CartProvider';
import PaypalButton from 'components/PaypalButton';
import PaymentSuccessPopup from 'components/Marketplace/PaymentSuccessPopup';
import { ReactComponent as CartIcon } from 'svgs/cart.svg';
import { ReactComponent as CrossIcon } from 'svgs/cross.svg';
import ajaxRequest, { ajaxRequestMethods } from 'helpers/ajaxRequest';
import { setToastMessage, ToastType } from 'helpers/Toast';
import EmptyCart from 'components/Marketplace/EmptyCart';
import styles from 'components/Marketplace/Cart/index.module.css';

const tableBorderClasses = 'border border-solid border-collapse';

const PopupType = {
  NONE: -1,
  PAYMENT_SUCCESS: 0,
};
const Cart = ({ discountPercentage }) => {
  const { cartItems, cartActions, dispatchCartAction } =
    useContext(CartContext);

  const subtotal = cartItems?.reduce(
    (acc, item) => (acc += item.imagePrice),
    0,
  );

  const totalDiscount = discountPercentage
    ? (subtotal * discountPercentage) / 100
    : 0;
  const total = subtotal - totalDiscount;

  const [popupType, setPopupType] = useState(PopupType.NONE);
  const [paymentDetails, setPaymentDetails] = useState(undefined);

  useEffect(() => {
    const onPaymentSuccessOrFailMessage = (event) => {
      if (event.data.status === 'success') {
        // Handle successful payment
        setToastMessage(
          `Payment successful with paymentId: ${event.data.paymentId}`,
        );
        dispatchCartAction(cartActions.clearCart());
        // Optionally close the popup
        if (event.source && event.source.close) {
          event.source.close();
        }

        setPaymentDetails({
          paymentId: event.data.paymentId,
          downloadToken: event.data.downloadToken,
        });

        setPopupType(PopupType.PAYMENT_SUCCESS);
      } else if (event.data.status === 'fail') {
        setToastMessage('Payment failed!', ToastType.ERROR);
      }
    };
    window.addEventListener('message', onPaymentSuccessOrFailMessage);

    return () => {
      window.removeEventListener('message', onPaymentSuccessOrFailMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const paypalClickHandler = async () => {
    const popup = window.open(
      '',
      'paypalPopup',
      'width=800,height=600,scrollbars=yes,resizable=yes',
    );

    try {
      const response = await ajaxRequest(
        'api/payment/paypal/create',
        ajaxRequestMethods.POST,
        { imageIds: cartItems.map((cartItem) => cartItem.imageId) },
      );

      const approvalUrl = response.data.approvalUrl;
      popup.location.href = approvalUrl;
    } catch (error) {
      if (popup) {
        popup.close();
      }
      console.error('Error creating PayPal payment:', error);
    }
  };

  const [showCart, setShowCart] = useState(true);
  const cartRef = useRef();
  useEffect(() => {
    if (window.screen.width < 768) {
      setShowCart(false);
    }
  }, []);

  return (
    <div
      ref={cartRef}
      className={`right-0 flex flex-col ${
        showCart ? 'fixed bg-gray-300 md:relative' : 'fixed md:absolute'
      } rounded p-0.5 text-xs lg:text-base`}
    >
      {showCart ? (
        <CrossIcon
          className="cursor-pointer"
          onClick={() => setShowCart(false)}
        />
      ) : (
        <div className="relative z-10">
          <div className="absolute -bottom-3 left-2 text-xs text-black sm:text-sm">
            {cartItems.length}
          </div>
          <CartIcon
            onClick={() => setShowCart((prev) => !prev)}
            className="cursor-pointer rounded-lg bg-yellow-300 p-0.5"
          />
        </div>
      )}
      <div
        className={`flex shrink-0 flex-col items-center overflow-y-auto rounded bg-gray-300 py-2 transition-all duration-200 ${
          showCart ? 'px-7' : 'invisible'
        } ${styles['cart-scrollbar']}`}
        style={{
          height: '60vh',
          scrollbarGutter: 'stable',
          width: showCart ? '230px' : '0px',
        }}
      >
        {popupType === PopupType.PAYMENT_SUCCESS && (
          <PaymentSuccessPopup
            {...paymentDetails}
            onClose={() => setPopupType(PopupType.NONE)}
          />
        )}

        {cartItems?.length > 0 ? (
          <table className={`border-black ${tableBorderClasses} text-sm`}>
            <thead>
              <th className={`pr-6 ${tableBorderClasses}`}>Design Name</th>
              <th className={`pr-4 ${tableBorderClasses}`}> Price</th>
            </thead>
            {cartItems?.map((item) => (
              <tr key={item.imageId} className="h-8">
                <td className={`${tableBorderClasses} pl-1`}>
                  {item.imageName}
                </td>
                <td className={`${tableBorderClasses} pl-1`}>
                  {item.imagePrice}
                </td>
                <td className={`${tableBorderClasses} p-0.5`}>
                  <CrossIcon
                    className="h-5 w-5 cursor-pointer text-red-500"
                    onClick={() => {
                      dispatchCartAction(
                        cartActions.removeFromCart(item.imageId),
                      );
                    }}
                  />
                </td>
              </tr>
            ))}

            <>
              {discountPercentage && (
                <>
                  <tr className="border border-solid border-transparent text-sm font-semibold">
                    <td>Subtotal</td>
                    <td>${subtotal}</td>
                  </tr>
                  <tr className="border border-solid border-transparent text-sm font-semibold">
                    <td>
                      {' '}
                      <span className="-ml-1 rounded-xl bg-green-600 p-0.5 pb-1">
                        Discount
                      </span>
                    </td>
                    <td>${totalDiscount}</td>
                  </tr>
                </>
              )}
              <tr className="border border-solid border-transparent text-sm font-semibold">
                <td>Total</td>
                <td>${total}</td>
              </tr>
            </>
          </table>
        ) : (
          <EmptyCart />
        )}

        <PaypalButton
          className="mt-4"
          onClick={paypalClickHandler}
          disabled={subtotal === 0}
        ></PaypalButton>
      </div>
    </div>
  );
};

export default Cart;
