import { useContext, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as yup from 'yup';

import LabelAndInputText from 'components/common/LabelAndInputText';
import ajaxRequest, { ajaxRequestMethods } from 'helpers/ajaxRequest';
import Button from 'components/common/Button';
import Toast from 'helpers/Toast';
import { AuthContext } from 'context/AuthProvider';
import YupHelper from 'helpers/yup';
import slugUtils from 'helpers/slugUtils';

export const imageCategorySchema = yup.object({
  imageCategoryName: yup
    .string()
    .min(3, 'Category name must be at least 3 characters')
    .max(50, 'Category name must be at most 50 characters')
    .required('Category name is required'),

  slug: yup
    .string()
    .required('Slug is required')
    .test(
      'is-valid-slug',
      'Invalid slug',
      (value) => slugUtils.isValidSlug(value), // Using the isValidSlug function for validation
    ),
});

const AddNewImageCategory = () => {
  const [newImageCategoryData, setNewImageCategoryData] = useState({
    imageCategoryName: '',
    slug: '',
  });
  const [validationError, setValidationError] = useState({
    imageCategoryName: '',
    slug: '',
  });
  const { auth } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const invalidateImageCategoriesCache = () => {
    queryClient.invalidateQueries(['imageCategory/all']);
  };

  const { mutate, isPending } = useMutation({
    mutationFn: (newImageCategoryData) =>
      ajaxRequest(
        'api/imageCategory',
        ajaxRequestMethods.POST,
        newImageCategoryData,
        auth?.accessToken,
      ),
    onSuccess: () => {
      Toast.setToastMessage(
        'Image category added successfully.',
        Toast.ToastType.SUCCESS,
      );
      setNewImageCategoryData({ imageCategoryName: '', slug: '' });
      invalidateImageCategoriesCache();
    },
    onError: (error) => {
      Toast.setToastMessage(error.message, Toast.ToastType.ERROR);
    },
  });

  const onSubmit = async () => {
    imageCategorySchema
      .validate(newImageCategoryData, { abortEarly: false })
      .then(() => {
        mutate(newImageCategoryData);
      })
      .catch((errors) => {
        setValidationError(YupHelper.extractYupValidationErrors(errors));
      });
  };

  return (
    <div className="flex flex-col gap-y-4" style={{ width: '250px' }}>
      <LabelAndInputText
        label="Image Category Name"
        value={newImageCategoryData.imageCategoryName}
        onValueChange={(imageCategoryName) => {
          setNewImageCategoryData((prev) => ({
            ...prev,
            imageCategoryName,
            slug: slugUtils.generateSlug(imageCategoryName),
          }));
        }}
        error={validationError.imageCategoryName}
        clearError={() =>
          setValidationError((prev) => ({
            ...prev,
            imageCategoryName: '',
            slug: '',
          }))
        }
        placeholder="i.e., Urban Landscapes"
      />

      <LabelAndInputText
        label="Slug"
        value={newImageCategoryData.slug}
        onValueChange={(slug) =>
          setNewImageCategoryData((prev) => ({ ...prev, slug }))
        }
        error={validationError.slug}
        clearError={() => setValidationError((prev) => ({ ...prev, slug: '' }))}
        placeholder="urban-landscapes"
      />

      <Button disabled={isPending} onClick={onSubmit} className="w-32">
        Submit
      </Button>
    </div>
  );
};

export default AddNewImageCategory;
