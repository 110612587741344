import { useContext, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { confirmAlert } from 'react-confirm-alert';
import { number, object, string } from 'yup';

import Button from 'components/common/Button';
import LabelAndInputText from 'components/common/LabelAndInputText';
import Toast from 'helpers/Toast';
import Yup from 'helpers/yup';
import ajaxRequest, { ajaxRequestMethods } from 'helpers/ajaxRequest';
import { AuthContext } from 'context/AuthProvider';

const discountSchema = object({
  percentage: number()
    .moreThan(0)
    .max(100)
    .required()
    .label('Discount Percentage'),
  cta: string().required().label('CTA'),
});

const AddNewDiscount = ({ onClose }) => {
  const [discountDetails, setDiscountDetails] = useState({
    percentage: null,
    cta: '',
    notifySubscribers: true,
  });

  const [validationError, setValidationError] = useState({
    percentage: '',
    cta: '',
  });

  const { auth } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const invalidateDiscountCache = () => {
    queryClient.invalidateQueries(['activeDiscount', 'discountHistory']);
  };

  const {
    mutate: addNewDiscountApiCall,
    isPending: isAddNewDiscountApiCallInProgress,
  } = useMutation({
    mutationFn: (discountDetails) =>
      ajaxRequest(
        'api/discount',
        ajaxRequestMethods.POST,
        discountDetails,
        auth?.accessToken,
      ),
    onSuccess: () => {
      Toast.setToastMessage(
        'Discount Added Successfully.',
        Toast.ToastType.SUCCESS,
      );
      invalidateDiscountCache();
      onClose();
    },
    onError: (error) => {
      Toast.setToastMessage(error.message, Toast.ToastType.ERROR);
    },
  });

  const onSubmit = () => {
    discountSchema
      .validate(discountDetails, { abortEarly: false })
      .then(() => {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="fixed inset-0 z-50 flex h-screen w-screen items-center justify-center bg-black/50">
                <div className="w-fit rounded bg-white px-4 py-4">
                  <h1>Are you sure?</h1>
                  <p>
                    You are going to add a new discount. This will deactivate
                    the currently active discount.
                  </p>
                  <div className="flex gap-x-2">
                    <Button className="px-4" onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      className="bg-red-500"
                      onClick={() => {
                        addNewDiscountApiCall(discountDetails);
                        invalidateDiscountCache();
                        onClose();
                      }}
                    >
                      Proceed
                    </Button>
                  </div>
                </div>
              </div>
            );
          },
        });
      })
      .catch((errors) => {
        setValidationError(Yup.extractYupValidationErrors(errors));
      });
  };

  return (
    <div className="flex flex-col gap-y-4">
      <LabelAndInputText
        value={discountDetails.percentage}
        onValueChange={(percentage) =>
          setDiscountDetails((prev) => ({ ...prev, percentage }))
        }
        error={validationError.percentage}
        clearError={() =>
          setValidationError((prev) => ({ ...prev, percentage: '' }))
        }
        label="Discount Percentage"
        placeholder="i.e., 10"
        type="number"
        min={0}
        max={100}
        containerClasses="w-96"
      />
      <LabelAndInputText
        value={discountDetails.cta}
        onValueChange={(cta) =>
          setDiscountDetails((prev) => ({ ...prev, cta }))
        }
        error={validationError.cta}
        clearError={() => setValidationError((prev) => ({ ...prev, cta: '' }))}
        label="CTA"
        placeholder="i.e., 10% Discount - Limited Time Offer!"
        type="text"
      />
      <div className="flex gap-x-2">
        <label className="font-semibold">Notify Subscribers: </label>
        <input
          type="checkbox"
          onChange={(e) => {
            setDiscountDetails((prev) => ({
              ...prev,
              notifySubscribers: e.target.checked,
            }));
          }}
          defaultChecked={discountDetails.notifySubscribers}
        />
      </div>

      <div className="mt-4 flex gap-x-2">
        <Button
          disabled={isAddNewDiscountApiCallInProgress}
          onClick={onSubmit}
          className="w-24"
        >
          Submit
        </Button>
        <Button
          className="w-24 bg-white"
          style={{
            border: 'rgb(59 130 246 ) 1px solid',
            color: 'rgb(59 130 246 )',
          }}
          onClick={() => onClose()}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AddNewDiscount;
