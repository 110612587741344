import { useContext, useState } from 'react';

import Button from 'components/common/Button';
import LabelAndInputText from 'components/common/LabelAndInputText';
import { AuthContext } from 'context/AuthProvider';
import ajaxRequest, { ajaxRequestMethods } from 'helpers/ajaxRequest';
import Toast from 'helpers/Toast';

const GetDownloadUrl = ({ onClose, paymentId }) => {
  const { auth } = useContext(AuthContext);
  const [expiryTime, setExpiryTime] = useState('');
  const [validationError, setValidationError] = useState('');
  const [
    isGetDownloadTokenApiCallInProgress,
    setIsGetDownloadTokenAPiCallInProgress,
  ] = useState(false);

  const onSubmit = async () => {
    if (expiryTime === '') {
      setValidationError('Please give expiry Time.');
    } else {
      try {
        setIsGetDownloadTokenAPiCallInProgress(true);
        const response = await ajaxRequest(
          `api/order/downloadToken?paymentId=${paymentId}&urlExpiryTime=${expiryTime}`,
          ajaxRequestMethods.GET,
          undefined,
          auth.accessToken,
        );
        const downloadUrl = `${window.location.protocol}//${window.location.host}/Download/${response.data.downloadToken}`;
        navigator.clipboard.writeText(downloadUrl);

        Toast.setToastMessage(
          `Download Link Copied for downloading resources of paymentId= ${paymentId}`,
        );
        onClose();
      } catch (error) {
        Toast.setToastMessage(error.message, Toast.ToastType.ERROR);
      } finally {
        setIsGetDownloadTokenAPiCallInProgress(false);
      }
    }
  };

  return (
    <div className="fixed inset-0 flex h-screen w-screen items-center justify-center bg-black/50">
      <div className="flex w-72 flex-col gap-y-2 rounded-lg bg-white p-2">
        <LabelAndInputText
          value={paymentId}
          label="Payment Id"
          type="text"
          disabled
        />
        <LabelAndInputText
          value={expiryTime}
          onValueChange={(expiryTime) => setExpiryTime(expiryTime)}
          error={validationError}
          clearError={() => setValidationError('')}
          label="Link Expiry Time"
          placeholder="i.e., 5m, 1h, 2d, etc."
          type="text"
        />
        <div className="flex gap-x-2">
          <Button
            onClick={onSubmit}
            disabled={isGetDownloadTokenApiCallInProgress}
          >
            Submit
          </Button>
          <Button
            className="bg-white"
            style={{
              border: 'rgb(59 130 246 ) 1px solid',
              color: 'rgb(59 130 246 )',
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GetDownloadUrl;
