import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import routes from 'constants/routes';
import { AuthContext } from 'context/AuthProvider';

const RequireLogout = ({ children }) => {
  const { auth } = useContext(AuthContext);

  const navigate = useNavigate();
  const isLoggedout = !auth?.accessToken;

  useEffect(() => {
    if (!isLoggedout) {
      navigate(routes.adminAppHome);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedout]);

  return <>{isLoggedout && children}</>;
};

export default RequireLogout;
