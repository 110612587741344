import { ReactComponent as LetterTick } from 'svgs/letter-tick.svg';
import Button from 'components/common/Button';

const SubscriptionVerificationLinkSentPopup = ({ onClose }) => {
  return (
    <div className="fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-black/40">
      <div className="mx-2 flex w-96 max-w-full flex-col items-center justify-center gap-y-2 rounded-lg bg-white py-8">
        <LetterTick />

        <span className="mt-6 text-xl font-medium" style={{ color: 'black' }}>
          Email Verification Link Sent
        </span>

        <span className="font-nomal px-2.5 text-center text-base text-gray-500">
          We have sent you a verification link to your email. Please verify your
          email by clicking the link. If you don't see the email in your inbox,
          kindly check your spam folder.
        </span>

        <Button className="w-64" onClick={onClose}>
          Close
        </Button>
      </div>
    </div>
  );
};
export default SubscriptionVerificationLinkSentPopup;
