import { useContext, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { createPortal } from 'react-dom';
import { useQueryClient } from '@tanstack/react-query';

import ImageCanvas from 'components/ImageCanvas';
import Button from 'components/common/Button';
import { setToastMessage, ToastType } from 'helpers/Toast';
import ajaxRequest, { ajaxRequestMethods } from 'helpers/ajaxRequest';
import InprogressOverlay from 'components/AdminComponents/InprogressOverlay';
import { AuthContext } from 'context/AuthProvider';
import ImageDetails from 'components/AdminComponents/ImageDetails';

export const ImagesGridAdminView = ({ imagesData, imagesDataLoading }) => {
  const [selectedImageUrl, setSelectedImageUrl] = useState('');
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const { auth } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const invalidateImagesCache = () => {
    queryClient.invalidateQueries(['image/all']);
  };

  const [selectedImagId, setSelectedImageId] = useState(undefined);
  const [displayImageDetails, setDisplayImageDetails] = useState(false);

  const onDeleteConfirm = async (imageId) => {
    try {
      setDeleteInProgress(true);

      await ajaxRequest(
        `api/image/${imageId}`,
        ajaxRequestMethods.DELETE,
        undefined,
        auth.accessToken,
      );
      setToastMessage('Image deleted successfully');
      invalidateImagesCache();
    } catch (error) {
      setToastMessage('Image deletion failed', ToastType.ERROR);
    } finally {
      setDeleteInProgress(false);
    }
  };
  const onDeleteClick = (imageId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="fixed inset-0 z-50 flex h-screen w-screen items-center justify-center bg-black/50">
            <div className="w-fit rounded bg-white px-4 py-4">
              <h1>Are you sure?</h1>
              <p>You want to delete this file?</p>
              <div className="flex gap-x-2">
                <Button className="px-4" onClick={onClose}>
                  No
                </Button>
                <Button
                  className="bg-red-500"
                  onClick={() => {
                    onDeleteConfirm(imageId);
                    onClose();
                  }}
                >
                  Yes, Delete it!
                </Button>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  const onDetailsClick = (imageId) => {
    setSelectedImageId(imageId);
    setDisplayImageDetails(true);
  };
  return (
    <div className="flex flex-wrap justify-center gap-6">
      {imagesDataLoading
        ? Array.from({ length: 20 }, () => (
            <div className="h-52 w-52 animate-pulse rounded-lg bg-gray-500"></div>
          ))
        : imagesData?.map((image) => (
            <div
              key={image.imageId}
              className="flex h-52 w-52 flex-col rounded-lg border border-solid border-gray-400 px-1.5 pb-2 pt-2"
            >
              <img
                src={image.imageUrl}
                alt={image.imageName}
                className="cursor-zoom-in overflow-hidden"
                onClick={() => setSelectedImageUrl(image.imageUrl)}
              />

              <div className="mt-1 flex flex-col gap-y-2">
                <div className="flex flex-row justify-between">
                  <span className="font-semibold">{image.imageName}</span>

                  <span>${image.imagePrice}</span>
                </div>
                <Button onClick={() => onDetailsClick(image.imageId)}>
                  Details
                </Button>
                <Button
                  className="bg-red-400"
                  onClick={() => onDeleteClick(image.imageId)}
                >
                  Delete
                </Button>
              </div>
            </div>
          ))}

      {selectedImageUrl &&
        createPortal(
          <ImageCanvas
            imgSrc={selectedImageUrl}
            onClose={() => setSelectedImageUrl('')}
            disableGA
          />,
          document.body,
        )}
      {deleteInProgress && <InprogressOverlay label="Deleting Image..." />}
      {displayImageDetails && (
        <ImageDetails
          selectedImageId={selectedImagId}
          onClose={(reloadPage) => {
            setDisplayImageDetails(false);
            if (reloadPage) window.location.reload();
          }}
        />
      )}
    </div>
  );
};

export default ImagesGridAdminView;
