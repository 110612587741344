import { useContext, useRef, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { confirmAlert } from 'react-confirm-alert';
import { AgGridReact } from 'ag-grid-react';
// Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-grid.css';
//optional css for data grid
import 'ag-grid-community/styles/ag-theme-quartz.css';

import Button from 'components/common/Button';
import { AuthContext } from 'context/AuthProvider';
import ajaxRequest, { ajaxRequestMethods } from 'helpers/ajaxRequest';
import deleteImageCategoryLambdaRequest from 'helpers/deleteImageCategoryLambdaRequest';
import { setToastMessage, ToastType } from 'helpers/Toast';
import InprogressOverlay from 'components/AdminComponents/InprogressOverlay';
import SearchText from 'components/common/SearchText';
import { ReactComponent as DeleteIcon } from 'svgs/delete.svg';
import { ReactComponent as EditIcon } from 'svgs/edit-solid.svg';
import UpdateImageCategory from './UpdateImageCategory';

const deleteImageCategoryMessage = 'Deleting the image category...';

const ImageCategoryList = () => {
  const { auth } = useContext(AuthContext);
  const {
    // isFetching: imageCategoriesLoading,
    // error: imageCategoriesError,
    data: imageCategoriesRes,
  } = useQuery({
    queryKey: ['imageCategory/all'],
    queryFn: () => ajaxRequest('api/imageCategory/all', ajaxRequestMethods.GET),
  });
  const imageCategories = imageCategoriesRes?.data;
  const [selectedImageCategory, setSelectedImageCategory] = useState(undefined);

  const colDefs = useRef([
    { field: '_id', headerName: 'Image Category Id' },
    { field: 'imageCategoryName' },
    { field: 'slug' },
    { field: 'imageCount' },
    {
      cellRenderer: (params) => {
        return (
          <DeleteIcon
            title={`delete category '${params.data.imageCategoryName}'.`}
            className="h-5 w-5 cursor-pointer"
            onClick={() => deleteImageCategoryClickHandler(params.data._id)}
          />
        );
      },
      width: 1,
      resizable: false,
      sortable: false,
    },
    {
      cellRenderer: (params) => {
        return (
          <EditIcon
            title={`edit category '${params.data.imageCategoryName}'.`}
            className="h-5 w-5 cursor-pointer text-black"
            onClick={() =>
              setSelectedImageCategory({
                imageCategoryId: params.data._id,
                imageCategoryName: params.data.imageCategoryName,
                slug: params.data.slug,
              })
            }
          />
        );
      },
      width: 1,
      resizable: false,
      sortable: false,
    },
  ]);
  const [searchText, setSearchText] = useState('');

  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const queryClient = useQueryClient();

  const invalidateImageCategoriesCache = () => {
    queryClient.invalidateQueries(['imageCategory/all']);
  };

  const onDeleteConfirm = async (imageCategoryIdToDelete) => {
    setDeleteInProgress(true);
    const response = await deleteImageCategoryLambdaRequest(
      imageCategoryIdToDelete,
      auth.accessToken,
    );

    setToastMessage(
      response.message,
      response.status === 200 ? ToastType.SUCCESS : ToastType.ERROR,
    );

    if (response.status === 200) invalidateImageCategoriesCache();

    setDeleteInProgress(false);
  };

  const deleteImageCategoryClickHandler = (imageCategoryIdToDelete) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="fixed inset-0 z-50 flex h-screen w-screen items-center justify-center bg-black/50">
            <div className="w-fit rounded bg-white px-4 py-4">
              <h1>Are you sure?</h1>
              <p>
                You want to delete this image cateogry (this will also deleted
                its images)?
              </p>
              <div className="flex gap-x-2">
                <Button className="px-4" onClick={onClose}>
                  No
                </Button>
                <Button
                  className="bg-red-500"
                  onClick={() => {
                    onDeleteConfirm(imageCategoryIdToDelete);
                    onClose();
                  }}
                >
                  Yes, Delete it!
                </Button>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  return (
    <div className="flex w-2/3 flex-col gap-y-2">
      <SearchText
        onTextChange={setSearchText}
        containerClasses="w-64 ml-auto mr-2"
        currentSearchText={searchText}
        placeholder="Search Image Categories"
      />

      <div className="ag-theme-quartz" style={{ height: 500, width: '100%' }}>
        <AgGridReact
          rowData={imageCategories}
          columnDefs={colDefs.current}
          pagination
          quickFilterText={searchText}
        />
      </div>

      {deleteInProgress && (
        <InprogressOverlay label={deleteImageCategoryMessage} />
      )}
      {selectedImageCategory && (
        <UpdateImageCategory
          imageCategoryExistingData={selectedImageCategory}
          onClose={() => {
            setSelectedImageCategory(undefined);
          }}
        />
      )}
    </div>
  );
};

export default ImageCategoryList;
