import { useContext, useRef, useState } from 'react';
import ReactLoading from 'react-loading';
import { useQuery } from '@tanstack/react-query';
import { confirmAlert } from 'react-confirm-alert';

import Button from 'components/common/Button';
import { AuthContext } from 'context/AuthProvider';
import ajaxRequest, { ajaxRequestMethods } from 'helpers/ajaxRequest';
import { ReactComponent as FileIcon } from 'svgs/file.svg';
import { ReactComponent as CloseIcon } from 'svgs/close.svg';
import MetadataComp, {
  MetaDataType,
} from 'components/AdminComponents/ImageDetails/MetadataComp';
import ImageCategoryDetails from 'components/AdminComponents/ImageDetails/ImageCategoryDetails';
import FileUpload from 'components/common/FileUpload';
import Toast from 'helpers/Toast';
import s3Upload from 'helpers/s3Upload';
import InprogressOverlay from 'components/AdminComponents/InprogressOverlay';

const PaidResourceView = ({ paidResourceName, paidResourceUrl }) => {
  const [isHovered, setIsHovered] = useState(false);
  const onDownloadClick = () => {
    fetch(paidResourceUrl)
      .then((response) => {
        if (!response.ok) throw new Error('Failed to fetch resource');
        return response.blob();
      })
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = paidResourceName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch((error) => console.error('Download error:', error));
  };
  return (
    <div
      className="relative flex w-fit flex-col items-center gap-y-2 rounded-lg p-2 transition-all duration-200 hover:bg-slate-300"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <FileIcon />
      <label>{paidResourceName}</label>

      {isHovered && (
        <div className="absolute z-50 flex h-full w-full items-center justify-center">
          <Button onClick={onDownloadClick}>Download</Button>
        </div>
      )}
    </div>
  );
};

const PreviewImageView = ({ imageUrl, imageName, setParentReloadNeeded }) => {
  const { auth } = useContext(AuthContext);

  const [imageUpdateInProgress, setImageUpdateInProgress] = useState(false);
  const previewImageUploadRef = useRef(null);
  const onPreviewImageUpdateClick = () => {
    previewImageUploadRef.current.click();
  };

  const previewImageRef = useRef(null);
  const uploadPreviewImageToS3 = async (previewImage) => {
    //upload preview image to s3 and get the url.
    try {
      setImageUpdateInProgress(true);
      const s3Key = imageUrl.split('/').pop();
      const {
        data: { url: previewImageUploadUrl },
      } = await ajaxRequest(
        `api/s3Url/upload/previewImage?s3Key=${s3Key}`,
        ajaxRequestMethods.GET,
        undefined,
        auth.accessToken,
      );
      await s3Upload(previewImageUploadUrl, previewImage);
      await ajaxRequest(
        'api/s3Url/invalidate-preview-image-cache',
        ajaxRequestMethods.POST,
        { s3Key },
        auth.accessToken,
      );

      previewImageRef.current.src = window.URL.createObjectURL(previewImage);
      setParentReloadNeeded(true);
    } catch (error) {
      Toast.setToastMessage(
        error.message || 'An error occurred',
        Toast.ToastType.ERROR,
      );
    } finally {
      setImageUpdateInProgress(false);
    }
  };

  const confirmUpdatePreviewImage = (previewImage) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="fixed inset-0 z-50 flex h-screen w-screen items-center justify-center bg-black/50">
            <div className="w-fit rounded bg-white px-4 py-4">
              <h1>Are you sure?</h1>
              <p>
                You want to update the preview image (this will delete the
                previous preview image)?
              </p>
              <div className="flex gap-x-2">
                <Button className="px-4" onClick={onClose}>
                  No
                </Button>
                <Button
                  className="bg-red-500"
                  onClick={() => {
                    uploadPreviewImageToS3(previewImage);
                    onClose();
                  }}
                >
                  Yes, Update!
                </Button>
              </div>
            </div>
          </div>
        );
      },
    });
  };
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="relative mx-auto flex w-fit flex-col"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={imageUrl}
        width={350}
        height={300}
        alt={imageName}
        ref={previewImageRef}
      />
      <FileUpload
        containerClasses="hidden"
        ref={previewImageUploadRef}
        onChange={confirmUpdatePreviewImage}
      />

      <div
        className={`absolute left-0 top-0 z-10 h-full w-full transition-all duration-200 ${isHovered ? 'bg-slate-300/80' : 'bg-none'}`}
      ></div>
      {isHovered && (
        <div className="absolute z-10 flex h-full w-full items-center justify-center">
          <Button onClick={onPreviewImageUpdateClick}>Upload New Image</Button>
        </div>
      )}

      {imageUpdateInProgress && (
        <InprogressOverlay label="Updating Preview Image..." />
      )}
    </div>
  );
};
const ImageDetails = ({ selectedImageId, onClose }) => {
  const { auth } = useContext(AuthContext);
  const [parentReloadNeeded, setParentReloadNeeded] = useState(false);
  const {
    data: imageData,
    isFetching: imageDetailsLoading,
    error: imageError,
  } = useQuery({
    queryKey: ['image', selectedImageId],
    queryFn: () =>
      ajaxRequest(
        `api/image/${selectedImageId}`,
        ajaxRequestMethods.GET,
        undefined,
        auth.accessToken,
      ),
  });

  const {
    isFetching: imageCategoriesLoading,
    data: imageCategoriesRes,
    error: imageCategoriesError,
  } = useQuery({
    queryKey: ['imageCategory/all'],
    queryFn: () => ajaxRequest('api/imageCategory/all', ajaxRequestMethods.GET),
  });

  const {
    imageUrl,
    imageId,
    imageName,
    paidResources,
    imagePrice,
    imageCategoryIds,
  } = imageData?.data || {};
  const allImageCategories = imageCategoriesRes?.data.map((imageCategory) => ({
    label: imageCategory.imageCategoryName,
    value: imageCategory._id,
  }));
  const isPageLoading = imageCategoriesLoading || imageDetailsLoading;
  const isPageError = imageCategoriesError || imageError;

  return (
    <div className="fixed inset-0 z-50 flex h-screen w-screen items-center justify-center bg-black/50">
      <div className="flex max-h-[70%] min-w-[50%] max-w-[60%] flex-col gap-y-2 rounded-lg bg-white">
        <div className="flex items-center justify-between border-0 border-b border-solid border-gray-200 p-2">
          <label className="text-lg font-semibold">Image Details</label>
          <CloseIcon
            className="h-10 w-10 cursor-pointer text-blue-500"
            onClick={() => {
              onClose(parentReloadNeeded);
            }}
          />
        </div>

        {isPageLoading ? (
          <div
            className="flex w-full items-center justify-center p-2"
            style={{ height: '30%' }}
          >
            <ReactLoading type="spin" color="blue" className="mx-auto" />
          </div>
        ) : isPageError ? (
          <div>There was an error; please refresh the page</div>
        ) : (
          <div className="flex flex-col gap-y-2 overflow-y-auto p-2">
            <MetadataComp
              imageId={imageId}
              value={imageName}
              metadataFieldFor={MetaDataType.IMAGE_NAME}
            />

            <PreviewImageView
              imageUrl={imageUrl}
              imageName={imageName}
              setParentReloadNeeded={setParentReloadNeeded}
            />

            <MetadataComp
              imageId={imageId}
              value={imagePrice}
              metadataFieldFor={MetaDataType.IMAGE_PRICE}
            />

            <ImageCategoryDetails
              allImageCategories={allImageCategories}
              currentImageCategoryIds={imageCategoryIds}
              imageId={imageId}
            />
            <label className="mt-4 font-semibold">Paid Resources:</label>
            <div className="flex flex-wrap gap-2">
              {paidResources.map((paidResource) => (
                <PaidResourceView {...paidResource} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageDetails;
