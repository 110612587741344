import { useRef, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { useQuery } from '@tanstack/react-query';

import Header from 'components/Marketplace/Header';
import ImageCategories from 'components/Marketplace/ImageCategories';
import ajaxRequest, { ajaxRequestMethods } from 'helpers/ajaxRequest';
import ImagesGrid from 'components/Marketplace//ImagesGrid';
import Cart from 'components/Marketplace/Cart';
import CartProvider from 'context/CartProvider';
import ReactQueryParameters from 'constants/reactQueryParameters';
import Pagination from 'components/common/Pagination';
import SearchText from 'components/common/SearchText';
import routerUtils from 'helpers/routerUtils';
import DiscountBanner from 'components/Marketplace/DiscountBanner';
import slugUtils from 'helpers/slugUtils';
import ga from 'helpers/ga';

const SeoHeadTags = ({
  currentImageCategorySlug,
  currentImageCategoryName,
  pageNumber,
  totalPages,
}) => {
  const title = `Dxf Designs from ${currentImageCategoryName} Category - Page ${pageNumber}`;
  const description = `Page ${pageNumber} of our exclusive dxf collection for ${currentImageCategoryName} category.`;
  return (
    <HelmetProvider>
      <Helmet>
        {currentImageCategoryName && pageNumber && <title>{title}</title>}

        {[slugUtils.slugs.allDesigns, slugUtils.slugs.latestDesigns].find(
          (slug) => slug === currentImageCategorySlug,
        ) ? (
          <meta name="robots" content="noindex, follow" />
        ) : (
          <>
            {currentImageCategoryName && pageNumber && (
              <>
                <meta name="description" content={description} />
                {pageNumber > 1 && (
                  <link
                    rel="prev"
                    href={`https://www.epicdxfdesigns.com${routerUtils.getMarketplaceNavigateUrl(
                      currentImageCategorySlug,
                      pageNumber - 1,
                    )}`}
                  />
                )}
                {pageNumber < totalPages && (
                  <link
                    rel="next"
                    href={`https://www.epicdxfdesigns.com${routerUtils.getMarketplaceNavigateUrl(
                      currentImageCategorySlug,
                      pageNumber + 1,
                    )}`}
                  />
                )}
              </>
            )}
          </>
        )}
      </Helmet>
    </HelmetProvider>
  );
};
const Marketplace = () => {
  const { currentImageCategorySlug, pageNumber } = useParams();
  const [currentImageCategoryName, setCurrentImageCategoryName] = useState('');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const q = queryParams.get('q') || '';

  const [searchTerm, setSearchTerm] = useState(q);

  const debouncedSearch = useRef(
    debounce((query) => {
      navigate(
        routerUtils.getMarketplaceNavigateUrl(
          slugUtils.slugs.allDesigns,
          pageNumber,
          query,
        ),
      );

      ga.sendGaEvent(
        ga.GaEventCategories.IMAGE_BROWSING.category,
        ga.GaEventCategories.IMAGE_BROWSING.events.SEARCH_TEXT_CHANGE,
        query,
      );
    }, 500),
  );

  const {
    isFetching: imagesDataLoading,
    // error: imagesDataError,
    data: imagesData,
  } = useQuery({
    queryKey: ['image/all', { currentImageCategorySlug, pageNumber, q }],
    queryFn: () =>
      ajaxRequest(
        `api/image/all?${
          currentImageCategorySlug !== slugUtils.slugs.allDesigns
            ? `imageCategorySlug=${currentImageCategorySlug}&`
            : ''
        }${q ? `q=${q}&` : ''}pageNumber=${pageNumber - 1}`,
        ajaxRequestMethods.GET,
      ),
    enabled: currentImageCategorySlug !== slugUtils.slugs.latestDesigns,
    staleTime: ReactQueryParameters.queryStaleTime,
    gcTime: ReactQueryParameters.queryGcTime,
  });

  const {
    isFetching: latestImagesDataLoading,
    // error: imagesDataError,
    data: latestImagesData,
  } = useQuery({
    queryKey: ['image/latest', { pageNumber }],
    queryFn: () =>
      ajaxRequest(
        `api/image/latest?pageNumber=${pageNumber - 1}`,
        ajaxRequestMethods.GET,
      ),
    enabled: currentImageCategorySlug === slugUtils.slugs.latestDesigns,
    staleTime: ReactQueryParameters.queryStaleTime,
    gcTime: ReactQueryParameters.queryGcTime,
  });

  const { data: activeDiscountData } = useQuery({
    queryKey: ['activeDiscount'],
    queryFn: () => ajaxRequest(`api/discount`, ajaxRequestMethods.GET),
    staleTime: ReactQueryParameters.queryStaleTime,
    gcTime: ReactQueryParameters.queryGcTime,
  });

  const navigate = useNavigate();

  const onPageChange = (updatedPage) => {
    navigate(
      routerUtils.getMarketplaceNavigateUrl(
        currentImageCategorySlug,
        updatedPage + 1,
        q,
      ),
    );
    ga.sendGaEvent(
      ga.GaEventCategories.IMAGE_BROWSING.category,
      ga.GaEventCategories.IMAGE_BROWSING.events.PAGE_CHANGE,
      `page: ${updatedPage + 1}, category: ${currentImageCategoryName}`,
    );
  };

  return (
    <div className="md:pl-2">
      <SeoHeadTags
        pageNumber={parseInt(pageNumber)}
        currentImageCategoryName={currentImageCategoryName}
        currentImageCategorySlug={currentImageCategorySlug}
        totalPages={
          currentImageCategorySlug === slugUtils.slugs.latestDesigns
            ? latestImagesData?.data.totalPages
            : imagesData?.data.totalPages
        }
      />
      <Header />
      <DiscountBanner activeDiscountData={activeDiscountData?.data} />

      <div className="mt-2.5 flex w-full">
        <ImageCategories
          onImageCategoryChange={(
            updatedImageCategoryName,
            imageCategoryLinkClicked,
          ) => {
            if (imageCategoryLinkClicked) setSearchTerm('');

            setCurrentImageCategoryName(updatedImageCategoryName);
          }}
          currentImageCategorySlug={currentImageCategorySlug}
        />
        <CartProvider>
          <div className="flex w-full flex-col items-center gap-y-4 px-6 md:px-4">
            <SearchText
              containerClasses="w-[82%] sm:w-11/12"
              onTextChange={(searchTerm) => {
                setSearchTerm(searchTerm);
                debouncedSearch.current(searchTerm);
              }}
              placeholder="Search all images..."
              currentSearchText={searchTerm}
            />

            <ImagesGrid
              imagesData={
                currentImageCategorySlug === slugUtils.slugs.latestDesigns
                  ? latestImagesData?.data.images
                  : imagesData?.data.images
              }
              imagesDataLoading={
                currentImageCategorySlug === slugUtils.slugs.latestDesigns
                  ? latestImagesDataLoading
                  : imagesDataLoading
              }
            />
            <Pagination
              onPageChange={onPageChange}
              pageCount={
                currentImageCategorySlug === slugUtils.slugs.latestDesigns
                  ? latestImagesData?.data.totalPages
                  : imagesData?.data.totalPages
              }
              currentPageNo={pageNumber - 1}
            />
          </div>
          <Cart discountPercentage={activeDiscountData?.data?.percentage} />
        </CartProvider>
      </div>
    </div>
  );
};

export default Marketplace;
