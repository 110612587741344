import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ajaxRequest, { ajaxRequestMethods } from 'helpers/ajaxRequest';
import Button from 'components/common/Button';
import routerUtils from 'helpers/routerUtils';
import slugUtils from 'helpers/slugUtils';

const REDIRECTION_DELAY = 10;
const VerificationStatuses = {
  SUCCESSFUL: 0,
  FAILED: 1,
  IN_PROGRESS: 2,
};

const VerifyEmail = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [verificationStatus, setVerificationStatus] = useState(
    VerificationStatuses.IN_PROGRESS,
  );
  const [errorMessage, setErrorMessage] = useState('');

  const navigateToMarketplace = () => {
    navigate(
      routerUtils.getMarketplaceNavigateUrl(slugUtils.slugs.allDesigns, 1),
    );
  };
  const [timer, setTimer] = useState(REDIRECTION_DELAY);
  useEffect(() => {
    if (verificationStatus === VerificationStatuses.SUCCESSFUL) {
      const timer = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [verificationStatus]);
  useEffect(() => {
    if (timer === 0) {
      navigateToMarketplace();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  useEffect(() => {
    if (token) {
      ajaxRequest(`api/subscription/verify/${token}`, ajaxRequestMethods.GET)
        .then((_) => {
          setVerificationStatus(VerificationStatuses.SUCCESSFUL);
        })
        .catch((error) => {
          setVerificationStatus(VerificationStatuses.FAILED);
          setErrorMessage(error.message);
        });
    }
  }, [token]);

  return (
    <div className="m-1">
      {verificationStatus === VerificationStatuses.IN_PROGRESS ? (
        'Verifying your email...'
      ) : verificationStatus === VerificationStatuses.FAILED ? (
        <>
          {errorMessage}{' '}
          <Button onClick={navigateToMarketplace}>Go to Home Page Now</Button>
        </>
      ) : (
        <>
          {`Verification successful, redirecting to home screen in ${timer} seconds. `}{' '}
          <Button onClick={navigateToMarketplace}>Go to Home Page Now</Button>
        </>
      )}
    </div>
  );
};

export default VerifyEmail;
