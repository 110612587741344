import { useContext, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { imageCategorySchema } from 'components/AdminComponents/AddNewImageCategory';
import LabelAndInputText from 'components/common/LabelAndInputText';
import Button from 'components/common/Button';
import Yup from 'helpers/yup';
import slugUtils from 'helpers/slugUtils';
import Toast from 'helpers/Toast';
import ajaxRequest, { ajaxRequestMethods } from 'helpers/ajaxRequest';
import { AuthContext } from 'context/AuthProvider';
import { ReactComponent as CloseIcon } from 'svgs/close.svg';

const UpdateImageCategory = ({ imageCategoryExistingData, onClose }) => {
  const [updatedImageCategoryData, setUpdatedImageCategoryData] = useState({
    imageCategoryName: imageCategoryExistingData.imageCategoryName,
    slug: imageCategoryExistingData.slug,
  });
  const [validationError, setValidationError] = useState({
    imageCategoryName: '',
    slug: '',
  });

  const { auth } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const invalidateImageCategoriesCache = () => {
    queryClient.invalidateQueries(['imageCategory/all']);
  };

  const {
    mutate: makeUpdateImageCategoryApiCall,
    isPending: isImageCategoryUpdateApiCallInProgress,
  } = useMutation({
    mutationFn: () =>
      ajaxRequest(
        `api/imageCategory/${imageCategoryExistingData.imageCategoryId}`,
        ajaxRequestMethods.PATCH,
        updatedImageCategoryData,
        auth?.accessToken,
      ),
    onSuccess: () => {
      Toast.setToastMessage(
        'Image category updated successfully.',
        Toast.ToastType.SUCCESS,
      );

      invalidateImageCategoriesCache();
      onClose();
    },
    onError: (error) => {
      Toast.setToastMessage(error.message, Toast.ToastType.ERROR);
    },
  });

  const onSubmit = () => {
    if (
      imageCategoryExistingData.imageCategoryName ===
        updatedImageCategoryData.imageCategoryName &&
      imageCategoryExistingData.slug === updatedImageCategoryData.slug
    ) {
      Toast.setToastMessage(
        'You did not update image category. Please update to proceed',
        Toast.ToastType.INFO,
      );
    } else {
      imageCategorySchema
        .validate(updatedImageCategoryData, { abortEarly: false })
        .then(() => {
          makeUpdateImageCategoryApiCall();
        })
        .catch((errors) => {
          setValidationError(Yup.extractYupValidationErrors(errors));
        });
    }
  };

  return (
    <div className="fixed inset-0 z-10 flex h-screen w-screen items-center justify-center bg-black/60">
      <div className="flex w-96 flex-col gap-y-2 rounded-lg bg-white">
        <div className="flex items-center justify-between border-0 border-b border-solid border-gray-200 p-2">
          <label className="text-lg font-semibold">Update Image Category</label>
          <CloseIcon
            className="h-10 w-10 cursor-pointer text-blue-500"
            onClick={onClose}
          />
        </div>

        <div className="flex flex-col gap-y-2 p-2">
          <LabelAndInputText
            value={updatedImageCategoryData.imageCategoryName}
            onValueChange={(imageCategoryName) => {
              setUpdatedImageCategoryData((prev) => ({
                ...prev,
                imageCategoryName,
                slug: slugUtils.generateSlug(imageCategoryName),
              }));
            }}
            error={validationError.imageCategoryName}
            clearError={() =>
              setValidationError((prev) => ({
                ...prev,
                imageCategoryName: '',
                slug: '',
              }))
            }
            label="Image Category Name"
            type="text"
          />
          <LabelAndInputText
            value={updatedImageCategoryData.slug}
            onValueChange={(slug) => {
              setUpdatedImageCategoryData((prev) => ({
                ...prev,
                slug,
              }));
            }}
            error={validationError.slug}
            clearError={() =>
              setValidationError((prev) => ({ ...prev, slug: '' }))
            }
            label="Slug"
            type="text"
          />

          <div className="flex justify-center gap-x-2">
            <Button
              disabled={isImageCategoryUpdateApiCallInProgress}
              onClick={onSubmit}
              className="w-32"
            >
              Submit
            </Button>

            <Button
              onClick={onClose}
              className="w-32 bg-white"
              style={{ color: 'black', border: 'solid 1px black' }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateImageCategory;
