const EmptyCart = () => {
  return (
    <div
      className="relative w-full bg-center bg-no-repeat"
      style={{ backgroundImage: "url('/empty-cart.svg')", height: '150px' }}
    >
      <span
        className="absolute top-16 text-sm text-gray-900"
        style={{ left: '86px' }}
      >
        Cart is empty
      </span>
    </div>
  );
};
export default EmptyCart;
