import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useDebounce } from 'customHooks';
import ReactQueryParameters from 'constants/reactQueryParameters';
import ajaxRequest, { ajaxRequestMethods } from 'helpers/ajaxRequest';
import ImagesGridAdminView from 'components/AdminComponents/ImagesGridAdminView';
import SearchText from 'components/common/SearchText';
import Pagination from 'components/common/Pagination';
import ImageCategories from 'components/ImageCategories';

const ImagesTable = () => {
  const [currentPageNo, setCurrentPageNo] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearchTerm = useDebounce(searchTerm);
  const [currentImageCategory, setCurrentImageCategory] = useState(undefined);

  useEffect(() => {
    setCurrentPageNo(0);
  }, [debouncedSearchTerm, currentImageCategory]);

  const {
    isFetching: imagesDataLoading,
    // error: imagesDataError,
    data: imagesData,
  } = useQuery({
    queryKey: [
      'image/all',
      { currentPageNo, debouncedSearchTerm, currentImageCategory },
    ],
    queryFn: () =>
      ajaxRequest(
        `api/image/all?pageNumber=${currentPageNo}${
          debouncedSearchTerm ? `&q=${debouncedSearchTerm}` : ''
        }${
          currentImageCategory ? `&imageCategoryId=${currentImageCategory}` : ''
        }`,
        ajaxRequestMethods.GET,
      ),
    staleTime: ReactQueryParameters.queryStaleTime,
    gcTime: ReactQueryParameters.queryGcTime,
  });

  return (
    <div className="mr-10 flex w-full flex-col items-center justify-center gap-y-4">
      <ImageCategories
        currentImageCategory={currentImageCategory}
        setCurrentImageCategory={setCurrentImageCategory}
      />
      <SearchText containerClasses="w-full" onTextChange={setSearchTerm} />
      <Pagination
        currentPageNo={currentPageNo}
        onPageChange={setCurrentPageNo}
        pageCount={imagesData?.data.totalPages}
      />
      <ImagesGridAdminView
        imagesData={imagesData?.data.images}
        imagesDataLoading={imagesDataLoading}
      />
    </div>
  );
};

export default ImagesTable;
