import ajaxRequest, { ajaxRequestMethods } from 'helpers/ajaxRequest';
import { useEffect, useRef } from 'react';

const { useLocation } = require('react-router-dom');

const ExecutePaypalPayment = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paymentId = queryParams.get('paymentId');
  const PayerID = queryParams.get('PayerID');
  const paymentApiExecuted = useRef(false);

  useEffect(() => {
    const executePayment = async () => {
      try {
        const response = await ajaxRequest(
          `api/payment/paypal/execute?paymentId=${paymentId}&PayerID=${PayerID}`,
          ajaxRequestMethods.GET,
        );

        window.opener.postMessage(
          {
            status: 'success',
            paymentId,
            downloadToken: response.data.downloadToken.downloadToken,
          },
          '*',
        );
      } catch (error) {
        window.opener.postMessage({ status: 'fail' }, '*');
      }
    };

    if (!paymentApiExecuted.current) {
      paymentApiExecuted.current = true;
      executePayment();
    }
  }, [paymentId, PayerID]);

  return <div>Executing your payment. Please do not close this window!</div>;
};

export default ExecutePaypalPayment;
