import ReactLoading from 'react-loading';

const InprogressOverlay = ({ label }) => (
  <div className="fixed inset-0 z-10 flex h-screen w-screen flex-col items-center justify-center gap-y-2 bg-black/60">
    <ReactLoading type="spin" color="white" />
    <span className="text-2xl text-white">{label}</span>
  </div>
);

export default InprogressOverlay;
