import { parse as parseContentDisposition } from 'content-disposition-attachment';

import HttpError from 'constants/HttpError';
import apiServerUrl from 'helpers/apiServerUrl';

export const ajaxRequestMethods = Object.freeze({
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
});

const ajaxRequest = async (url, method, data, authToken = null) => {
  const options = { method, headers: {} };

  options.headers = {
    ...(data && { 'Content-Type': 'application/json' }),
    ...(authToken && { Authorization: `Bearer ${authToken}` }),
  };

  if (data) options.body = JSON.stringify(data);

  const baseUrl = apiServerUrl();
  const fullUrl = `${baseUrl}/${url}`;

  try {
    const response = await fetch(fullUrl, options);

    let data = null;
    const contentType = response.headers.get('Content-Type');
    if (contentType.startsWith('application/zip')) data = await response.blob();
    else data = await response.json();

    const { attachment, filename } = parseContentDisposition(
      response.headers.get('Content-Disposition'),
    );

    if (!response.ok) {
      throw new HttpError(response.status, data.message, data.errors);
    }
    let returnResponse = {
      status: response.status,
      data,
      ...(attachment && { fileName: filename }),
    };
    return returnResponse;
  } catch (error) {
    if (error instanceof HttpError) throw error;
    throw new HttpError(500, 'Could not connect to server');
  }
};

export default ajaxRequest;
