import { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';

import ajaxRequest from 'helpers/ajaxRequest';
import Button from 'components/common/Button';

const PaymentSuccessPopup = ({ paymentId, downloadToken, onClose }) => {
  // const downloadLink = `/download/${downloadToken}`;

  const [isDownloading, setIsDownloading] = useState(false);
  const [showRedownload, setShowRedownload] = useState(false);
  const [downloadMessage, setDownloadMessage] = useState(false);

  const downloadHandler = () => {
    setIsDownloading(true);
    setShowRedownload(false);
    setDownloadMessage('');
    ajaxRequest(`api/s3Url/download/paidResources/${downloadToken}`)
      .then((res) => {
        const url = URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.href = url;
        link.download = res.fileName || 'images.zip';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        // onClose();
        setDownloadMessage('Data successfully downloaded.');
      })
      .catch((response) => {
        if (response.status === 401)
          setDownloadMessage('Download link has expired!');
        else setDownloadMessage('Download failed!');
      })
      .finally(() => {
        setIsDownloading(false);
        setShowRedownload(true);
      });
  };
  useEffect(() => {
    if (downloadToken) downloadHandler();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadToken]);

  return (
    <div
      style={{ zIndex: 11 }}
      className="fixed inset-0 flex h-screen w-screen items-center justify-center bg-black/50"
    >
      <div className="flex w-3/4 flex-col gap-y-5 rounded-md bg-white p-4">
        {isDownloading && (
          <div className="flex flex-col items-center justify-center">
            <ReactLoading type="spin" color="blue" />
            <span className="text-2xl">Downloading...</span>
            <span className="text-center text-lg">
              In case the browser asks your permission to allow downloading the
              file, please allow.
            </span>
          </div>
        )}

        {showRedownload && (
          <div className="flex flex-col items-center gap-y-2 text-lg">
            <span>{downloadMessage}</span>
            <div className="w-full text-center">
              Problems dowloading?{' '}
              <Button className="w-24" onClick={downloadHandler}>
                Redownload
              </Button>
            </div>
          </div>
        )}
        {/* <div className="flex flex-col gap-y-2">
          <div>
            <b> Instructions:</b>
            <br />
            Your resources will be download automatically. Alternatively, if you
            are having download issues, you can click here:{" "}
            <a href={downloadLink}>Click Here!</a>
          </div>
        </div> */}

        <Button onClick={onClose} className="mx-auto w-32">
          Close
        </Button>
      </div>
    </div>
  );
};

export default PaymentSuccessPopup;
