import React, { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from 'components/common/Button';
import routerUtils from 'helpers/routerUtils';
import slugUtils from 'helpers/slugUtils';
import { useMutation } from '@tanstack/react-query';
import ajaxRequest, { ajaxRequestMethods } from 'helpers/ajaxRequest';
import Toast from 'helpers/Toast';
import InprogressOverlay from 'components/AdminComponents/InprogressOverlay';

const Unsubscribe = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token') || '';
  const email = queryParams.get('email') || '';

  const {
    mutateAsync: unsubscribe,
    isPending: isUnsubscribeApiCallInProgress,
  } = useMutation({
    mutationFn: () =>
      ajaxRequest(
        `api/subscription/cancel?token=${token}&email=${email}`,
        ajaxRequestMethods.POST,
      ),
  });

  const navigate = useNavigate();

  const navigateToHomePage = () => {
    navigate(
      routerUtils.getMarketplaceNavigateUrl(slugUtils.slugs.latestDesigns, 1),
      { replace: true },
    );
  };

  const onConfirmUnsubscribe = () => {
    unsubscribe()
      .then((unsubscribeApiResponse) =>
        Toast.setToastMessage(unsubscribeApiResponse.data.message),
      )
      .catch((unsubscribeApiError) => {
        Toast.setToastMessage(
          unsubscribeApiError.message,
          Toast.ToastType.ERROR,
        );
      })
      .finally(() => {
        navigateToHomePage();
      });
  };

  useEffect(() => {
    confirmUnsubscribePopup();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmUnsubscribePopup = React.useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="fixed inset-0 z-50 flex h-screen w-screen items-center justify-center bg-black/60">
            <div className="w-fit rounded bg-white px-4 py-4">
              <h1>Are you sure?</h1>
              <p>Unsubscribe {email} from www.epicdxfdesigns.com?</p>
              <div className="flex gap-x-2">
                <Button
                  className="px-4"
                  onClick={() => {
                    onClose();
                    navigateToHomePage();
                  }}
                >
                  No
                </Button>
                <Button
                  className="bg-red-500"
                  onClick={() => {
                    onClose();
                    onConfirmUnsubscribe();
                  }}
                  disabled={isUnsubscribeApiCallInProgress}
                >
                  Yes, unsubscribe
                </Button>
              </div>
            </div>
          </div>
        );
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isUnsubscribeApiCallInProgress && (
        <InprogressOverlay
          label={`Processing unsubscribe request for ${email}`}
        />
      )}
    </>
  );
};

export default Unsubscribe;
