import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga4';

import App from 'App';
import 'index.css';
import 'tailwind.css';
import AuthProvider from 'context/AuthProvider';
import { BrowserRouter as Router } from 'react-router-dom';

if (process.env.REACT_APP_GA_MEASUREMENT_ID)
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Router>
          <App />
        </Router>
      </AuthProvider>
    </QueryClientProvider>

    <ToastContainer />
  </React.StrictMode>,
);
