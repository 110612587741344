import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { string, object } from 'yup';

import Yup from 'helpers/yup';
import Toast from 'helpers/Toast';
import routerUtils from 'helpers/routerUtils';
import slugUtils from 'helpers/slugUtils';
import ajaxRequest, { ajaxRequestMethods } from 'helpers/ajaxRequest';
import Button from 'components/common/Button';
import LabelAndInputText from 'components/common/LabelAndInputText';
import { AuthContext } from 'context/AuthProvider';

// Define the login validation schema
const loginSchema = object({
  email: string().email('Invalid email format').required('Email is required'),
  password: string().required('Password is required'),
});

const Login = () => {
  // Set initial state for login details and validation errors
  const [loginDetails, setLoginDetails] = useState({ email: '', password: '' });
  const [validationErrors, setValidationErrors] = useState({
    email: '',
    password: '',
  });

  const [isLoginInProgress, setIsLoginInProgress] = useState(false);

  const { authActions, dispatchAuthAction } = useContext(AuthContext);

  // Handle form submission
  const onSubmit = () => {
    // Validate login details using the schema
    loginSchema
      .validate(loginDetails, { abortEarly: false })
      .then(() => {
        setIsLoginInProgress(true);
        ajaxRequest('api/admin/login', ajaxRequestMethods.POST, loginDetails)
          .then((response) => {
            const { data } = response;
            dispatchAuthAction(authActions.setAuth(data));
          })
          .catch((error) => {
            Toast.setToastMessage(error.message, Toast.ToastType.ERROR);
          })
          .finally(() => {
            setIsLoginInProgress(false);
          });
      })
      .catch((error) => {
        // Extract and set validation errors if validation fails
        setValidationErrors(Yup.extractYupValidationErrors(error));
      });
  };

  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center gap-y-4">
      <div className="flex w-96 flex-col gap-y-4 rounded-lg bg-gray-200 p-4">
        <LabelAndInputText
          label="Email"
          type="email"
          error={validationErrors.email}
          clearError={() => {
            setValidationErrors((prev) => ({ ...prev, email: '' }));
          }}
          onValueChange={(email) =>
            setLoginDetails((prev) => ({ ...prev, email }))
          }
        />
        <LabelAndInputText
          label="Password"
          type="password"
          error={validationErrors.password}
          clearError={() => {
            setValidationErrors((prev) => ({ ...prev, password: '' }));
          }}
          onValueChange={(password) =>
            setLoginDetails((prev) => ({ ...prev, password }))
          }
        />
        <Button onClick={onSubmit} disabled={isLoginInProgress}>
          Submit
        </Button>

        <Link
          to={routerUtils.getMarketplaceNavigateUrl(
            slugUtils.slugs.allDesigns,
            1,
          )}
        >
          Switch to User App
        </Link>
      </div>
    </div>
  );
};

export default Login;
